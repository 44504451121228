import React from "react";

const loaderContainer = {
  position: 'fixed',
  top: '270px',
  left: 0,
  width: '100%',
  heigth: '100%',
  backgroundColor: '#F8F8F8AD',
  // display: 'none'
};

const loader = {
  left: '55%',
  top: '50%',
  zIndex: '1000',
  position: 'absolute'
};

const NoRecordFound = (props) => {
  const outerDiv = props && props.loaderContainer ? props.loaderContainer : loaderContainer;
  const innerDiv = props && props.loader ? props.loader : loader;
  return (
    <div style={outerDiv}>
      <div style={innerDiv}>
        <span className="h3-old">No Record Found</span>
      </div>
    </div>
  )
};

export default NoRecordFound;