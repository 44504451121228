import React, { useEffect } from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import { getUserId, postMethod } from "services/httpServices";
import { setSuperAgentId } from "services/httpServices";

const AgentHeader = (props) => {
  const [totalAmt, setAmount] = React.useState("");
  const [receivedAmt, setReceivedAmt] = React.useState("");
  const [transferAmt, setTransferAmt] = React.useState("");
  const [balanceLoading, setBalLoading] = React.useState(false);
  const [mothlyReceived, setMonthlyReceived] = React.useState("")
  const [totalCashTopup, setCashTopup] = React.useState("")
  const [monthlyCashTopup, setMonthlyCashTopup] = React.useState("")
  const { addToast } = useToasts();

  useEffect(() => {
    fetchBalance();
  }, []);

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const fetchBalance = () => {
    let errorMsg = "";
    let id = getUserId();
    setBalLoading(true);
    postMethod("/api/dashboard/SubAgent/", {}, "post")
      .then((res) => {
        setBalLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (res.data.result.hasOwnProperty("totalWalletBalance")) {
              setAmount(res.data.result.totalWalletBalance);
            } else {
              setAmount(0);
            }

            if (res.data.result.hasOwnProperty("superAgentId")) {
              setSuperAgentId(res.data.result.superAgentId)
            } 

            if (res.data.result.hasOwnProperty("totalCashTopup")) {
                setCashTopup(res.data.result.totalCashTopup);
            } else {
                setCashTopup(0);
            }

            if (res.data.result.hasOwnProperty("monthlyReceived")) {
              setMonthlyReceived(res.data.result.monthlyReceived);
            } else {
                setMonthlyReceived(0);
            }

            if (res.data.result.hasOwnProperty("monthlyCashTopup")) {
              setMonthlyCashTopup(res.data.result.monthlyCashTopup);
            } else {
                setMonthlyCashTopup(0);
            }

            if (res.data.result.hasOwnProperty("totalReceivedBalance")) {
              setReceivedAmt(res.data.result.totalReceivedBalance);
            } else {
              setReceivedAmt(0);
            }

            if (res.data.result.hasOwnProperty("totalTransfers")) {
              setTransferAmt(res.data.result.totalTransfers);
            } else {
              setTransferAmt(0);
            }
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setBalLoading(false);
        notifyFailed("Internal Server Error");
      });
  };

  const getAmt = (amt) => {
    return amt?.toLocaleString();
  };

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Available Balance</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i class="fas fa-money-bill-alt"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(totalAmt)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Monthly Received</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i class="fas fa-money-check"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(mothlyReceived)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Total Received</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-purple text-white rounded-circle shadow">
                          <i class="fas fa-wallet"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(receivedAmt)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>

            </Row>

            <Row className="mt-3">
              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Monthly Topup</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                          <i class="fas fa-money-bill-wave"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(monthlyCashTopup)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>


              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Total Topup</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i class="fas fa-money-check-alt"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(totalCashTopup)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default AgentHeader;
