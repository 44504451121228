import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input, InputGroupText, InputGroupAddon, InputGroup,
  Container,
  Row,
  Col,
  Label
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { useToasts } from 'react-toast-notifications';
import { checkSpace, postMethod, getUserId } from "services/httpServices";
import { PIN_HINT } from "appConfig";
import swal from 'sweetalert';
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import { useHistory } from "react-router";


const initialValue = {
  'otp': '',
  'pin': '',
  'confirmPin': ''
};

const ChangePin = () => {

  const [values, setValues] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState(null);
  const [showPin, setShowPin] = React.useState(false);
  const [showConfirmPin, setShowConfirmPin] = React.useState(false);
  const [otpLoading, setotpLoading] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);
  const { addToast } = useToasts();
  const history = useHistory();




  /**======================== React Hooks ==========================*/

  useEffect(() => {
    setotpLoading(true);
    genOtp();
  }, []);


  /**======================== File Functions ==========================*/
  const resendOtp = (e) => {
    e.preventDefault();
    setValues({ ...values, 'otp': '' });
    setSpinner(true);
    genOtp();
  }

  const openModal = (msg) => {
    swal({
      // title: msg,
      icon: "success",
      text: msg,
      allowOutsideClick: false,
      allowEscapeKey: false,
      closeOnClickOutside: false,
      buttons:
      {
        confirm: {
          text: "Ok",
          visible: true,
          closeModal: true,
          className: 'sweet-btn'
        }
      }
    }).then((confirm) => {
      if (confirm) {
        setForm(1);
      } else {
        setForm(1);
      }
    });
  }

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const notifySuccess = (text) => {
    if (!text) {
      text = 'Save successfully';
    }
    addToast(text, {
      appearance: 'success',
      autoDismiss: true,
    });
  }




  /**======================== Form Validation function ==========================*/

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      verifyOtp();
    }
  }

  const validate = (inputs) => {
    let errors = {};

    if (!inputs.otp) {
      errors.otp = "OTP is required";
    }
    return errors;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      if (name === "pin") {
        if (pinValidation(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === "confirmPin") {
        if (pinValidation(value)) {
          validateConfirmPswd(value);
          setValues({ ...values, [name]: value });
        }
      } else if (name === "otp") {
        if (checkOTP(value)) {
          setValues({ ...values, [name]: value });
        }
      } else {
        setValues({ ...values, [name]: value });
      }
    }
  }


  const pinValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 4) {

      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const checkOTP = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (digiRegex.test(value)) {
      if (value.length <= 6) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const validateConfirmPswd = (value) => {
    let error = {};
    if (value !== values.pin) {
      error.confirmPin = 'Confirm Pin should match Pin';
    }
    setErrors(error);
  }

  const handleSubmitV2 = (e) => {
    e.preventDefault();
    const validationErrors = validateV2(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      changePin();
    }
  }

  const validateV2 = (inputs) => {
    let errors = {};

    if (!inputs.otp) {
      errors.otp = "OTP is required";
    }
    if (!inputs.pin) {
      errors.pin = "PIN is required";
    } else if (values.pin.length !== 4) {
      errors.pin = 'Pin should have 4 digits';
    }
    if (!inputs.confirmPin) {
      errors.confirmPin = "Confirm PIN is required";
    } else if (inputs.confirmPin.length !== 4) {
      errors.confirmPin = 'Confirm PIN should have 4 digits';
    } else if (values.pin !== values.confirmPin) {
      errors.confirmPin = 'Confirm Pin should match Pin';
    }
    return errors;
  }



  /**======================== API Calls ==========================*/

  const genOtp = () => {
    var errorMsg = '';
    var data = JSON.stringify({
      "UserId": getUserId(),
      "OtpType": "ChangePIN"
    });
    // setLoading(true);
    let url = '/api/Transactions/Sendotp';
    postMethod(url, data, 'post')
      .then(res => {
        setLoading(false);
        setSpinner(false);
        setotpLoading(false);
        if (res.data) {
          if (res.data.responseCode === "200") {
            let msg = res.data.responseMessage || 'Otp sent';
            // notifySuccess(msg);
            openModal(msg);
          } else if (res.data.responseCode === "400") {
            errorMsg = res.data.responseMessage || 'Error in generating otp';
            notifyFailed(errorMsg);
          } else {
            errorMsg = 'Unknown error in generating otp';
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);
        }
      }).catch(err => {
        setLoading(false);
        setotpLoading(false);
        setSpinner(false);
        errorMsg = 'Internal Server Error';
        notifyFailed(errorMsg);
      });
  }


  const verifyOtp = () => {
    var errorMsg = '';
    var data = JSON.stringify({
      "UserId": getUserId(),
      "OtpType": "ChangePIN",
      "Otp": values.otp
    });
    setLoading(true);
    let url = '/api/Transactions/verifyotp';
    postMethod(url, data, 'post')
    .then(res => {
      if (res.data) {
        if (res.data.responseCode === "200") {
          setForm(2);
          setLoading(false);
        } else if (res.data.responseCode === "400") {
          errorMsg = res.data.responseMessage || 'Error in generating otp';
          notifyFailed(errorMsg);
          setLoading(false);
        } else {
          errorMsg = 'Unknown error in generating otp';
          notifyFailed(errorMsg);
          setLoading(false);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        setLoading(false);
      }
    }).catch(err => {
      errorMsg = 'Internal Server Error';
      notifyFailed(errorMsg);
      setLoading(false);
    });
  }

  const changePin = () => {
    var errorMsg = '';
    var data = JSON.stringify({
      "PIN": values.pin,
      "OTP": values.otp
    });
    setLoading(true);
    let url = '/api/Auth/changepin';
    postMethod(url, data, 'post')
    .then(res => {
      if (res.data) {
        if (res.data.responseCode === "200") {
          notifySuccess('Pin has changed successfully');
          history.push('/index');
        } else if (res.data.responseCode === "400") {
          errorMsg = res.data.responseMessage || 'Error in generating otp';
          notifyFailed(errorMsg);
          setLoading(false);
        } else {
          errorMsg = 'Unknown error in generating otp';
          notifyFailed(errorMsg);
          setLoading(false);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        setLoading(false);
      }
    }).catch(err => {
      errorMsg = 'Internal Server Error';
      notifyFailed(errorMsg);
      setLoading(false);
    });
  }


  return (
    <>
      <Header />
      <Container className="mt--5 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="6">
            <Card className="bg-secondary shadow border-0">

              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col lg="12">
                    <h3 className="mb-0 text-center">Change Pin</h3>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody className="px-lg-5 py-lg-4" style={{ minHeight: '300px' }}>
                {otpLoading && <FullPageLoader />}
                {!otpLoading && form == 1 &&
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Row className="mt-5">
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-otp">
                            OTP
                          </label>

                          <div className="float-right text-small">
                            <small>
                              <a href="#pablo" onClick={resendOtp}>
                                Resend
                              </a> &nbsp; &nbsp;
                              {spinner &&
                                <div class="spinner-border spinner-border-sm" role="status">
                                  <span class="sr-only"></span>
                                </div>}
                            </small>
                          </div>

                          <Input
                            className="form-control-alternative"
                            id="input-otp"
                            placeholder="Enter OTP"
                            type="text"
                            name="otp"
                            value={values.otp}
                            onChange={handleInputChange}
                          />
                          <div className="text-left text-muted">
                            <small><i style={{ fontSize: '12px' }}>*
                              we have sent you an OTP on your registered mobile number / email</i></small>
                          </div>
                          {errors.otp && <div className="text-left text-danger">
                            <small>* {errors.otp}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col lg-12>
                        <div>
                          <Button className="btn float-right"
                            color="primary" type="submit"
                            disabled={loading}>
                            Verify {loading && <i class="fa fa-spinner fa-spin"></i>}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                }


                {form == 2 &&
                  <Form onSubmit={handleSubmitV2} autoComplete="off">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-pin"
                          >
                            PIN
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              className="form-control-alternative"
                              id="input-pin"
                              type={showPin ? "text" : "password"}
                              name="pin"
                              value={values.pin}
                              placeholder="Enter 4-digit Pin"
                              onChange={handleInputChange}
                            />
                            {!showPin && <InputGroupAddon addonType="append">
                              <InputGroupText>
                                <i className="fa fa-eye" onClick={() => setShowPin(true)} />
                              </InputGroupText>
                            </InputGroupAddon>
                            }

                            {showPin && <InputGroupAddon addonType="append">
                              <InputGroupText>
                                <i className="fa fa-eye-slash" onClick={() => setShowPin(false)} />
                              </InputGroupText>
                            </InputGroupAddon>}
                          </InputGroup>
                          * <small><i>{PIN_HINT}</i></small>
                          {errors.pin && <div className="text-left text-danger">
                            <small>* {errors.pin}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-confirm-pin"
                          >
                            Confirm PIN
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              className="form-control-alternative"
                              id="input-confirm-pin"
                              type={showConfirmPin ? "text" : "password"}
                              name="confirmPin"
                              placeholder="Enter Confirm Pin"
                              value={values.confirmPin}
                              onChange={handleInputChange}
                            />
                            {!showConfirmPin && <InputGroupAddon addonType="append">
                              <InputGroupText>
                                <i className="fa fa-eye" onClick={() => setShowConfirmPin(true)} />
                              </InputGroupText>
                            </InputGroupAddon>
                            }

                            {showConfirmPin && <InputGroupAddon addonType="append">
                              <InputGroupText>
                                <i className="fa fa-eye-slash" onClick={() => setShowConfirmPin(false)} />
                              </InputGroupText>
                            </InputGroupAddon>}
                          </InputGroup>
                          {errors.confirmPin && <div className="text-left text-danger">
                            <small>* {errors.confirmPin}</small>
                          </div>}
                        </FormGroup>
                      </Col>

                    </Row>

                    <Row>
                      <Col lg-6>
                        <FormGroup>
                          <Button
                            className="btn float-right mt-4"
                            color="primary" type="submit"
                            disabled={loading}
                          >
                            Save and Next {loading && <i class="fa fa-spinner fa-spin"></i>}
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>

                }

              </CardBody>


            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );




}

export default ChangePin;