import React, { useEffect } from "react";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import {
  Table
} from "reactstrap";
import moment from "moment";
import { getSortCls, reportloader } from "services/util";
import { btnCss, iconCss } from '../../appConfig';
import FullPageLoader from "components/FullPageLoader/fullpageloader";


const sortIS = {
  sortCreatedat: 0,
  sortMerchantName: 0,
  sortBank_MMOCode: 0,
  sortSubscriberMobileNumber: 0,
  sortAmount: 0,
  sortProductId: 0,
  sortReferenceId: 0,
  sortStatus: 0,
  sortUpdatedat: 0
};

const reportloaderContainer = {
  position: 'absolute',
  top: '280px',
  left: 0,
  width: '100%',
  heigth: '100%',
  backgroundColor: '#F8F8F8AD',
  // display: 'none'
};

const CollectionReportTable = (props) => {

  const [filteredList, setFilteredList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [sort, setSort] = React.useState(sortIS);


  useEffect(()=> {
    setFilteredList(props.datalist)
  }, [props.datalist]);

  useEffect(()=> {
    setCurrentPage(props.currentPage);
  }, [props.currentPage]);

  useEffect(()=> {
    setPageSize(props.pageSize);
  }, [props.pageSize]);

  useEffect(()=> {
    props.setParentSort(sort);
  }, [sort]);

  const getAmt = (amt) => {
    return amt?.toLocaleString();
  }

  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = '1';
    } else if (fieldValue == "1") {
      newValue = '2';
    } else if (fieldValue == "2") {
      newValue = '1';
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  }

  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col"
              onClick={() => sortHandle(sort.sortCreatedat, 'sortCreatedat')}>
              Instruction Date/Time
              <button style={btnCss} >
                <i className={getSortCls(sort.sortCreatedat)}
                  onClick={() => sortHandle(sort.sortCreatedat, 'sortCreatedat')}
                />
              </button>
            </th>
            <th scope="col"
              onClick={() => sortHandle(sort.sortMerchantName, 'sortMerchantName')}>
              Merchant Name
              <button style={btnCss} >
                <i className={getSortCls(sort.sortMerchantName)}
                  onClick={() => sortHandle(sort.sortMerchantName, 'sortMerchantName')}
                />
              </button>
            </th>
            <th scope="col"
              onClick={() => sortHandle(sort.sortBank_MMOCode, 'sortBank_MMOCode')}>
              Bank/MMO Code
              <button style={btnCss} >
                <i className={getSortCls(sort.sortBank_MMOCode)}
                  onClick={() => sortHandle(sort.sortBank_MMOCode, 'sortBank_MMOCode')}
                />
              </button>
            </th>
            <th scope="col"
              onClick={() => sortHandle(sort.sortSubscriberMobileNumber, 'sortSubscriberMobileNumber')}>
              Customer UID
              <button style={btnCss} >
                <i className={getSortCls(sort.sortSubscriberMobileNumber)}
                  onClick={() => sortHandle(sort.sortSubscriberMobileNumber, 'sortSubscriberMobileNumber')}
                />
              </button>
            </th>
            <th scope="col"
              onClick={() => sortHandle(sort.sortAmount, 'sortAmount')}>
              Transaction Amount
              <button style={btnCss} >
                <i className={getSortCls(sort.sortAmount)}
                  onClick={() => sortHandle(sort.sortAmount, 'sortAmount')}
                />
              </button>
            </th>
            <th scope="col"
              onClick={() => sortHandle(sort.sortProductId, 'sortProductId')}>
              Reference Code
              <button style={btnCss} >
                <i className={getSortCls(sort.sortProductId)}
                  onClick={() => sortHandle(sort.sortProductId, 'sortProductId')}
                />
              </button>
            </th>
            <th scope="col"
              onClick={() => sortHandle(sort.sortReferenceId, 'sortReferenceId')}>
              Transaction Ref
              <button style={btnCss} >
                <i className={getSortCls(sort.sortReferenceId)}
                  onClick={() => sortHandle(sort.sortReferenceId, 'sortReferenceId')}
                />
              </button>
            </th>
            <th scope="col"
              onClick={() => sortHandle(sort.sortStatus, 'sortStatus')}>
              Status
              <button style={btnCss} >
                <i className={getSortCls(sort.sortStatus)}
                  onClick={() => sortHandle(sort.sortStatus, 'sortStatus')}
                />
              </button>
            </th>
            <th scope="col"
              onClick={() => sortHandle(sort.sortUpdatedat, 'sortUpdatedat')}>
              Clearing Date/Time
              <button style={btnCss} >
                <i className={getSortCls(sort.sortUpdatedat)}
                  onClick={() => sortHandle(sort.sortUpdatedat, 'sortUpdatedat')}
                />
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
        {filteredList.length > 0 && filteredList.map((mmo, i) => {
          return (
            <tr>
              <td scope="row">
                {moment(mmo.createdat).format('lll')}
              </td>
              <td>{mmo.merchantName}</td>
              <td>{mmo.bank_MMOCode}</td>
              <td>{mmo.subscriberMobileNumber}</td>
              <td>{getAmt(mmo.amount)}</td>
              <td>{mmo.productId}</td>
              <td>{mmo.referenceId}</td>
              <td>{mmo.status}</td>
              <td>{mmo.updatedat ? moment(mmo.updatedat).format('lll') : '--'}</td>
            </tr>

          )
        }
        )}
        </tbody>
        {props.loading && 
          <FullPageLoader label={"Fetching..."} 
            loaderContainer={reportloaderContainer}
            loader={reportloader}
          />
        }
        {!props.loading && !filteredList.length && 
          <NoRecordFound 
            loaderContainer={reportloaderContainer}
            loader={reportloader}
          />
        }
      </Table>       
    </>
  )
}

export default CollectionReportTable;