import React, { useEffect } from "react";
import { Button, Row, Col } from "reactstrap";
import { postMethod } from "services/httpServices";
import { useToasts } from "react-toast-notifications";
import moment from "moment";

const PendingSettlementsReport = (props) => {
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [isMounted, setIsMounted] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [submitButton, setSubmitButton] = React.useState(false);
  const { addToast } = useToasts();

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  useEffect(() => {
    if (isMounted) {
      fetchReport();
    }
  }, [props.currentPage]);

  useEffect(() => {
    if (isMounted) {
      fetchReport();
    }
  }, [props.sort]);

  useEffect(() => {
    props.parentLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (props.pdfLoading && isMounted) {
      fetchReportData();
    }
  }, [props.pdfLoading]);

  useEffect(() => {
    if (props.xlLoading && isMounted) {
      fetchReportData();
    }
  }, [props.xlLoading]);

  useEffect(() => {
    if (endDate && moment(new Date(endDate)).isBefore(new Date(startDate))) {
      setEndDate("");
    }
  }, [startDate]);

  const validate = () => {
    var error = {};
    if (!startDate) {
      error.startDate = "Please select start date";
    }
    if (!endDate) {
      error.endDate = "Please select end date";
    }
    return error;
  };

  const onSubmitReport = () => {
    setSubmitButton(true);
    if (props.currentPage != 0) {
      props.changePage(0);
    } else {
      fetchReport();
    }
  };

  const fetchReport = () => {
    // const validationErrors = validate();
    const validationErrors = {};
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError) {
      let errorMsg = "";
      setLoading(true);
      let data = {
        Status: "",
        PageNumber: Number(props.currentPage + 1),
        PerPage: pageSize,
      };

      if (props.sort && props.sort.sortMerchantName == "1") {
        data["SortingOn"] = "MerchantName";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortMerchantName == "2") {
        data["SortingOn"] = "CreatedAt";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortBankName == "1") {
        data["SortingOn"] = "BankName";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortBankName == "2") {
        data["SortingOn"] = "BankName";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortAccountNo == "1") {
        data["SortingOn"] = "AccountNo";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortAccountNo == "2") {
        data["SortingOn"] = "AccountNo";
        data["IsDescending"] = true;
      } else if (props.sort && props.sort.sortAmountDue == "1") {
        data["SortingOn"] = "AmountDue";
        data["IsDescending"] = false;
      } else if (props.sort && props.sort.sortAmountDue == "2") {
        data["SortingOn"] = "AmountDue";
        data["IsDescending"] = true;
      }

      postMethod(
        "/api/reports/MerchantPendingSettlementReport",
        JSON.stringify(data),
        "post"
      )
        .then((res) => {
          setLoading(false);
          setIsMounted(true);
          setSubmitButton(false);
          if (res && res.data) {
            if (res.data.responseCode === "200" && res.data.result) {
              // Success
              if (!res.data.result.list || !res.data.result.list.length) {
                res.data.result.list = [];
              }
              props.parentCallback(res.data.result);
            } else if (res.data.responseCode === "400") {
              // Error
              errorMsg = res.data.responseMessage || "Error in fetching list";
              notifyFailed(errorMsg);
            } else {
              // Unknown Error
              errorMsg = "Unknown Error in fetching";
              notifyFailed(errorMsg);
            }
          } else {
            errorMsg = "Unknown Error";
            notifyFailed(errorMsg);
          }
        })
        .catch((err) => {
          setLoading(false);
          setIsMounted(true);
          notifyFailed("Internal Server Error");
        });
    }
  };

  const fetchReportData = () => {
    // const validationErrors = validate();
    const validationErrors = {};
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError) {
      let errorMsg = "";
      let data = {
        Status: "",
        PageNumber: 1,
        PerPage: props.totalRec,
      };

      postMethod(
        "/api/reports/MerchantPendingSettlementReport",
        JSON.stringify(data),
        "post"
      )
        .then((res) => {
          setIsMounted(true);
          if (res && res.data) {
            if (res.data.responseCode === "200" && res.data.result) {
              // Success
              if (!res.data.result.list || !res.data.result.list.length) {
                res.data.result.list = [];
              }
              props.parentReportData(res.data.result);
            } else if (res.data.responseCode === "400") {
              // Error
              errorMsg = res.data.responseMessage || "Error in fetching list";
              notifyFailed(errorMsg);
              props.errorReportData();
            } else {
              // Unknown Error
              errorMsg = "Unknown Error in fetching";
              notifyFailed(errorMsg);
              props.errorReportData();
            }
          } else {
            errorMsg = "Unknown Error";
            notifyFailed(errorMsg);
            props.errorReportData();
          }
        })
        .catch((err) => {
          setIsMounted(true);
          notifyFailed("Internal Server Error");
          props.errorReportData();
        });
    }
  };

  return (
    <>
      <Row>
        <Col lg="12">
          <Button
            color="primary"
            className="mt-3"
            onClick={onSubmitReport}
            disabled={loading}
            style={{ marginLeft: 20 }}
          >
            Submit &nbsp; &nbsp;
            {loading && submitButton && <i class="fa fa-spinner fa-spin"></i>}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PendingSettlementsReport;
