import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
import { useToasts } from 'react-toast-notifications';
import { checkSpace, getLoginType, getUserId, postMethod } from '../../services/httpServices';
import { getAmt, DUMMY_USER } from "../../services/util";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MOBILE_ONLY_COUNTRY } from 'appConfig';


const initialValue = {
  'toMobWithCC': '',
  'toMobWithoutCC': '',
  'toMobCC': '',
  'amount': '',
  'totalAmt': ''
};

const STR = `Please enter the subscriber's mobile number and amount whom you want to transfer`;

const AddTopUpSubscriber = (props) => {

  const [values, setValues] = useState(initialValue);
  const [subsInfo, setSubsInfo] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState(1);
  const [spinner, setSpinner] = React.useState(false);

  const { addToast } = useToasts();

  /**======================== React Hooks ==========================*/



  /**======================== File Functions ==========================*/

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const notifySuccess = (text) => {
    if (!text) {
      text = 'Save successfully';
    }
    addToast(text, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  const checkNumberOnly = (value) => {
    let digiRegex = /^[1-9][\d]*$/;
    // let digiRegex = /^[+-]?([1-9]+\.?[0-9]*|\.[0-9]+)$/;
    if (digiRegex.test(value)) {
      return true;
    } else {
      return false;
    }
  }

  const goBack1 = () => {
    setValues(initialValue);
    setSubsInfo({});
    setForm(1);
  }

  const goBack2 = () => {
    setValues({ ...values, amount: '' });
    setForm(2);
  }

  const closeModal = () => {
    props.closeModal();
  }

  const phoneChange = (value, data, event, formattedValue) => {
    if (data.dialCode && data.dialCode.length) {
      setValues({
        ...values, toMobWithCC: value, toMobCC: data.dialCode,
        toMobWithoutCC: value.substring(data.dialCode.length)
      });
    }
    return;
  }

  /**======================== Form Validation function ==========================*/

  const handleInputChange = (ev) => {
    var { name, value } = ev.target;
    value = value.trim();
    if (name === "amount") {
      if (value == '') {
        setValues({ ...values, [name]: value });
      } else {
        if (checkNumberOnly(value)) {
          setValues({ ...values, [name]: value });
        } else {
          setValues({ ...values});
        }
      }
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    const validationErrors = validate1(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      checkToMob();
    }
  }

  const validate1 = (inputs) => {
    var errors = {};
    if (!inputs.toMobWithCC) {
      errors.toMobWithCC = 'Phone number is required';
    } else if (!inputs.toMobWithoutCC) {
      errors.toMobWithCC = 'Phone number is required';
    } else if (!inputs.amount) {
      errors.amount = 'Please enter the amount';
    }
    return errors;
  }

  const handleSubmit2 = (e) => {
    e.preventDefault();
    const validationErrors = validate2(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      getCommissionAmt();
    }
  }

  const validate2 = (inputs) => {
    var errors = {};
    if (!inputs.amount) {
      errors.amount = 'Amount is required';
    }
    return errors;
  }

  const handleSubmit3 = (e) => {
    e.preventDefault();
    const validationErrors = validate3(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      transferMoney();
    }
  }

  const validate3 = (inputs) => {
    var errors = {};
    if (!inputs.amount) {
      errors.amount = 'Amount is required';
    }
    return errors;
  }


  /**======================== API Calls ==========================*/

  const checkToMob = () => {

    /** =======testing ======= */
      // setSpinner(true);

      // setTimeout(()=> {
      //   setSubsInfo(DUMMY_USER);
      //   setSpinner(false);
      //   setForm(2);
      // }, 3000);

    /** ====================== */

    let errorMsg = '';
    setSpinner(true);
    let data = {
      Amount: Number(values.amount),
      Subscriber: values.toMobWithCC
    };
    let url = ""
    let loginType = getLoginType()
    if(loginType === "Agent") {
      url = "/api/Agent/validateCashTopup"
    } else {
      url = "/api/Transactions/validateCashTopup/"
    }
    postMethod(url, data, 'post')
    .then(res => {
      setSpinner(false);
      if (
        res && res.data
      ) {
        if (res.data.responseCode === "200") {
          // Success
          setSubsInfo(res.data.result);
          setForm(3);
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching user info';
          notifyFailed(errorMsg);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching user info';
          notifyFailed(errorMsg);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
      }
    }).catch(err => {
      setSpinner(false);
      notifyFailed('Internal Server Error');
    });
  }

  const getCommissionAmt = () => {
    /** =======testing ======= */
    setSpinner(true);

    setTimeout(()=> {
      setSpinner(false);
      setForm(3);
    }, 3000);

    /** ====================== */


    
    // var errorMsg = '';
    // var data = {
    //   "PaymentAmount": Number(values.amount),
    //   "Password": values.pin,
    //   "Email": getUserEmail().trim(),
    //   "PaymentType": "BalanceTransfer"
    // };

    // setSpinner(true);
    // let url = '/api/pos/PaymentPinVerification';
    // postMethod(url, JSON.stringify(data), 'post')
    // .then(res => {
    //   if (res.data) {
    //     if (res.data.responseCode === "200") {
    //       let result = res.data.result;
    //       setFees(result);
    //       setForm(3);
    //     } else if (res.data.responseCode === "400") {
    //       errorMsg = res.data.responseMessage || 'Error in validating pin';
    //       notifyFailed(errorMsg);
    //       setSpinner(false);
    //     } else {
    //       errorMsg = 'Unknown error in validating pin';
    //       notifyFailed(errorMsg);
    //       setSpinner(false);
    //     }
    //   } else {
    //     errorMsg = 'Unknown Error';
    //     notifyFailed(errorMsg);
    //     setSpinner(false);
    //   }
    // }).catch(err => {
    //   errorMsg = 'Internal Server Error';
    //   notifyFailed(errorMsg);
    //   setSpinner(false);
    // });
  }

  const transferMoney = () => {
    /** =======testing ======= */
      // setSpinner(true);

      // setTimeout(()=> {
      //   setSpinner(false);
      //   let msg = 'Amount Transferred';
      //   notifySuccess(msg);
      //   closeModal(true);
      // }, 3000);

    /** ====================== */
    var errorMsg = '';
    setSpinner(true);
    var data = new FormData();
    data.append('SubscriberUserId', subsInfo.subscriberId);
    data.append('Amount', subsInfo.amount);
    data.append('PaymentMethod', 'Cash');
    data.append('PaymentType', 'CashTopUp');
    data.append('PaymentTo', 'Wallet TopUp');
    data.append('AmountType', 'R');
    data.append('CollectionType', 'CashTopUp');
    data.append('FeesAmount', subsInfo.feesAmount);
    data.append('PaymentFrom', getUserId());
    data.append('PaymentMethodId', 0);
    let loginType = getLoginType()
    let url = "";
    if(loginType === "Agent") {
      url = "/api/Transactions/SubagentTopup"
    } else {
      url = '/api/transactions/Merchanttopup';
    }
    postMethod(url, data, 'post')
    .then(res => {
        setSpinner(false);
        if (res.data) {
          if (res.data.responseCode === "200") {
            let msg = res.data.responseMessage || 'Amount Transferred';
            notifySuccess(msg);
            closeModal();
          } else if (res.data.responseCode === "400") {
            errorMsg = res.data.responseMessage || 'Error in transferring amount';
            notifyFailed(errorMsg);
          } else {
            errorMsg = 'Unknown error in transferring amount';
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);
        }
    }).catch(err => {
      setSpinner(false);
      errorMsg = 'Internal Server Error';
      notifyFailed(errorMsg);
    });
  };

  return (
    <>
      <Container className="p-0">
        <Row>
          <Col xl="12">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Add Cash Top-up</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={closeModal}
                      size="xs"
                      close
                      // className="fa fa-times"
                      style={{ cursor: 'pointer', fontSize: '25px' }}
                    >
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody 
                // className="px-lg-5 py-lg-4" 
                style={{ minHeight: '300px' }}>
                {loading && <FullPageLoader />}
                {!loading &&
                  <>
                    {form == 1 &&
                      <Form role="form" onSubmit={handleSubmit1}>
                        <Row>
                          <Col>
                            <strong>
                              <label>{STR}</label>
                            </strong>
                          </Col>
                        </Row>


                        <Row className="mt-4">
                          <Col>
                            <FormGroup>
                              <PhoneInput
                                className="form-control-alternative"
                                country={'sl'}
                                value={values.toPhone}
                               // onlyCountries={MOBILE_ONLY_COUNTRY}
                                countryCodeEditable={false}
                                onChange={phoneChange}
                              />

                              {errors.toMobWithCC && <div className="text-left text-danger">
                                <small>* {errors.toMobWithCC}</small>
                              </div>}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-amount">
                                Amount
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-amount"
                                placeholder="Enter Amount"
                                type="text"
                                name="amount"
                                value={values.amount}
                                onChange={handleInputChange}
                                disabled={spinner}
                              />
                              {errors.amount && <div className="text-left text-danger">
                                <small>* {errors.amount}</small>
                              </div>}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row className="mt-3">
                          <Col lg-6>
                            <div>
                              <Button className="btn float-right"
                                color="primary" type="submit"
                                disabled={spinner}
                              >
                                Submit {spinner && <i class="fa fa-spinner fa-spin"></i>}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    }

                    {form == 2 &&
                      <Form onSubmit={handleSubmit2}>
                        <Row>
                          <Col lg="12">
                            <strong>User Details</strong>
                            <div className="mt-2">
                              <div>
                                <small>Transfer to - {subsInfo.subscriberName}</small>
                              </div>
                              <div>
                                <small className="mt-2">Mobile Number - {
                                  `(${subsInfo.mobileCodeId}) - ${subsInfo.mobNumber}`}
                                </small>
                              </div>
                              <div>
                                <small className="mt-2">Email - {subsInfo.email}
                                </small>
                              </div>
                            </div>

                          </Col>
                        </Row>

                        <Row className="mt-2">
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-amount">
                                Amount
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-amount"
                                placeholder="Enter Amount"
                                type="text"
                                name="amount"
                                value={values.amount}
                                onChange={handleInputChange}
                                disabled={spinner}
                              />
                              {errors.amount && <div className="text-left text-danger">
                                <small>* {errors.amount}</small>
                              </div>}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row className="mt-3">

                          <Col lg-6>
                            <div>
                              <Button className="btn float-left" color="primary"
                                disabled={spinner}
                                onClick={goBack1}>
                                <i class="fas fa-arrow-alt-circle-left"
                                  style={{ fontSize: '18px' }}></i>
                                &nbsp; Back
                              </Button>
                            </div>
                          </Col>

                          <Col lg-6>
                            <div>
                              <Button className="btn float-right"
                                color="primary" type="submit"
                                disabled={spinner}>
                                Submit {spinner && <i class="fa fa-spinner fa-spin"></i>}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    }

                    {form == 3 &&
                      <Form role="form" onSubmit={handleSubmit3}>
                        <Row>
                          <Col lg="12">
                            <strong>User Details</strong>
                            <div className="mt-2">
                              <div>
                                <small>Transfer to : {subsInfo.subscriberName}</small>
                              </div>
                              <div>
                                <small className="mt-2">Mobile Number : {
                                  `(${subsInfo.subscriberMobileCode}) : 
                                  ${subsInfo.subscriberMobileNumber}`}
                                </small>
                              </div>
                              <div>
                                <small className="mt-2">Email : {subsInfo.subscriberEmail}
                                </small>
                              </div>

                              <div>
                                <small className="mt-2">Cash Received : &nbsp;
                                  <strong>Le</strong> &nbsp;{getAmt(subsInfo.amount)}
                                </small>
                              </div>

                              <div>
                                <small className="mt-2">Top Up Amount : &nbsp;
                                  <strong>Le</strong> &nbsp;{getAmt(subsInfo.topUpAmount)}
                                </small>
                              </div>

                              <div>
                                <small className="mt-2">Commission : &nbsp;
                                  <strong>Le</strong> &nbsp;{getAmt(subsInfo.feesAmount)}
                                </small>
                              </div>
                            </div>

                          </Col>
                        </Row>

                        <Row className="mt-4">
                          <Col lg-6>
                            <div>
                              <Button className="btn float-left" color="primary"
                                onClick={goBack1}
                                disabled={spinner}>
                                <i class="fas fa-arrow-alt-circle-left" style={{ fontSize: '18px' }}></i> &nbsp;
                                Back
                              </Button>
                            </div>
                          </Col>
                          <Col lg-6>
                            <div>
                              <Button className="btn float-right" color="primary" type="submit"
                                disabled={spinner}>
                                Submit {spinner && <i class="fa fa-spinner fa-spin"></i>}
                              </Button>
                            </div>
                          </Col>
                        </Row>


                      </Form>
                    }
                  </>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );


}

export default AddTopUpSubscriber;
