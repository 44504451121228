import React, { useEffect } from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { useToasts } from 'react-toast-notifications';
import { getUserId, postMethod } from "services/httpServices";
import { subsMTDWithDrawal, subsMTDPayment, subsMTDWalletTrans } from "variables/charts";
import { daysInMonth, daysInArray, MonthArraySM } from "services/util";


const SubscriberHeader = (props) => {
  const [totalAmt, setAmount] = React.useState('');
  const [bankWithDraw, setBankWithDraw] = React.useState('');
  const [mmoWithDraw, setMMOWithDraw] = React.useState('');
  const [merchantPayment, setMerchantPayment] = React.useState('');
  const [receivedAmt, setReceivedAmt] = React.useState('');
  const [transferAmt, setTransferAmt] = React.useState('');
  const [balanceLoading, setBalLoading] = React.useState(false);
  const { addToast } = useToasts();

  useEffect(()=> {
    fetchBalance();
  }, []);

  /**===================== MTD Withdrawals ======================== */

  useEffect(()=> {
    props.showSubsMTDWithDrawLoader(true);
    setMTDWithDrawChart();
  }, [props.subsMTDWithDrawMonth]);

  useEffect(()=> {
    props.showSubsMTDWithDrawLoader(true);
    setMTDWithDrawChart();
  }, [props.subsMTDWithDrawYear]);



  /**========================= MTD Payment ===================================== */

  useEffect(()=> {
    props.showSubsMTDPaymentLoader(true);
    setMTDPaymentChart();
  }, [props.subsMTDPaymentMonth]);

  useEffect(()=> {
    props.showSubsMTDPaymentLoader(true);
    setMTDPaymentChart();
  }, [props.subsMTDPaymentYear]);

  /**========================= MTD Wallet Transfer ===================================== */

  useEffect(()=> {
    props.showSubsMTDWalletTransLoader(true);
    setMTDWalletTrans();
  }, [props.subsMTDWalletTransMonth]);

  useEffect(()=> {
    props.showSubsMTDWalletTransLoader(true);
    setMTDWalletTrans();
  }, [props.subsMTDWalletTransYear]);



  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const fetchBalance = () => {
    let errorMsg = '';
    let id = getUserId();
    setBalLoading(true);
    postMethod('/api/dashboard/subscriber/', {}, 'post')
    .then(res => {
      setBalLoading(false);
      if (
        res && res.data
      ) {
        if (res.data.responseCode === "200") {
          // Success
          if (res.data.result.hasOwnProperty('totalWalletBalance')) {
            setAmount(res.data.result.totalWalletBalance);
          } else {
            setAmount(0);
          }

          if (res.data.result.hasOwnProperty('totalBankWithdrawals')) {
            setBankWithDraw(res.data.result.totalBankWithdrawals);
          } else {
            setBankWithDraw(0);
          }

          if (res.data.result.hasOwnProperty('totalMMOWithdrawals')) {
            setMMOWithDraw(res.data.result.totalMMOWithdrawals);
          } else {
            setMMOWithDraw(0);
          }

          if (res.data.result.hasOwnProperty('totalMerchantPayments')) {
            setMerchantPayment(res.data.result.totalMerchantPayments);
          } else {
            setMerchantPayment(0);
          }

          if (res.data.result.hasOwnProperty('totalReceived')) {
            setReceivedAmt(res.data.result.totalReceived);
          } else {
            setReceivedAmt(0);
          }

          if (res.data.result.hasOwnProperty('totalTransfers')) {
            setTransferAmt(res.data.result.totalTransfers);
          } else {
            setTransferAmt(0);
          }

        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching list';
          notifyFailed(errorMsg);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching';
          notifyFailed(errorMsg);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
      }
    }).catch(err => {
      setBalLoading(false);
      notifyFailed('Internal Server Error');
    });
  }


  const getAmt = (amt) => {
    return amt?.toLocaleString();
  }


  const setMTDWithDrawChart = () => {
    var errorMsg = '';
    var data = {
      'Year': Number(props.subsMTDWithDrawYear),
      'Month': Number(props.subsMTDWithDrawMonth) + 1,
      'Status': 'Paid'
    };
    postMethod('/api/dashboard/subscriber/monthlywithdrawals/', data, 'post')
    .then(res => {
      if (
        res && res.data
      ) {
        if (
          res.data.responseCode === "200" && 
          res.data.result && res.data.result.list
        ) {
          // Success

          let month = Number(props.subsMTDWithDrawMonth);
          let year = Number(props.subsMTDWithDrawYear);
          let totalDays = daysInMonth(month + 1, year);
          let arr = daysInArray(totalDays);
          let bankArr = [];
          let mmoArr = [];

          for (let i = 0; i < arr.length; i++) {
            let val = res.data.result.list.find(l => l.day == arr[i]);
            if (val && val.hasOwnProperty('bankWithdrawal')) {
              bankArr.push(Number(val.bankWithdrawal));
            } else {
              bankArr.push(0);
            }
            if (val && val.hasOwnProperty('mmoWithdrawal')) {
              mmoArr.push(Number(val.mmoWithdrawal));
            } else {
              mmoArr.push(0);
            }
          }
          
          let mtdChart = subsMTDWithDrawal({
            daysArr: arr, 
            bankArr: bankArr,
            mmoArr: mmoArr
          });
          props.showSubsMTDWithDraw(mtdChart);
          props.showSubsMTDWithDrawLoader(false);
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching charts';
          notifyFailed(errorMsg);
          props.showSubsMTDWithDrawLoader(false);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching charts';
          notifyFailed(errorMsg);
          props.showSubsMTDWithDrawLoader(false);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        props.showSubsMTDWithDrawLoader(false);
      }
    }).catch(err => {
      notifyFailed('Internal Server Error');
      props.showSubsMTDWithDrawLoader(false);
    });
  }

  const setMTDPaymentChart = () => {
    var errorMsg = '';
    var data = {
      'Year': Number(props.subsMTDPaymentYear),
      'Month': Number(props.subsMTDPaymentMonth) + 1,
      'Status': 'Paid'
    };
    postMethod('/api/dashboard/subscriber/MonthlyMerchantPayment/', data, 'post')
    .then(res => {
      if (
        res && res.data
      ) {
        if (
          res.data.responseCode === "200" && 
          res.data.result && res.data.result.list
        ) {
          // Success
          let month = Number(props.subsMTDPaymentMonth);
          let year = Number(props.subsMTDPaymentYear);
          let totalDays = daysInMonth(month + 1, year);
          let arr = daysInArray(totalDays);
          let bankArr = [];
          let walletArr = [];

          for (let i = 0; i < arr.length; i++) {
            let val = res.data.result.list.find(l => l.day == arr[i]);
            if (val && val.hasOwnProperty('bankPayment')) {
              bankArr.push(Number(val.bankPayment));
            } else {
              bankArr.push(0);
            }

            if (val && val.hasOwnProperty('walletPayment')) {
              walletArr.push(Number(val.walletPayment));
            } else {
              walletArr.push(0);
            }
          }
          let mtdChart = subsMTDPayment({daysArr: arr, bankArr: bankArr, walletArr: walletArr});
          props.showSubsMTDPayment(mtdChart);
          props.showSubsMTDPaymentLoader(false);
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching charts';
          notifyFailed(errorMsg);
          props.showSubsMTDPaymentLoader(false);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching charts';
          notifyFailed(errorMsg);
          props.showSubsMTDPaymentLoader(false);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        props.showSubsMTDPaymentLoader(false);
      }
    }).catch(err => {
      notifyFailed('Internal Server Error');
      props.showSubsMTDPaymentLoader(false);
    });
  }

  const setMTDWalletTrans = () => {
    var errorMsg = '';
    var data = {
      'Year': Number(props.subsMTDWalletTransYear),
      'Month': Number(props.subsMTDWalletTransMonth) + 1,
      'Status': 'Paid'
    };
    postMethod('/api/dashboard/subscriber/MonthlyBalanceTransfer/', data, 'post')
    .then(res => {
      if (
        res && res.data
      ) {
        if (
          res.data.responseCode === "200" && 
          res.data.result && res.data.result.list
        ) {
          // Success
          let month = Number(props.subsMTDWalletTransMonth);
          let year = Number(props.subsMTDWalletTransYear);
          let totalDays = daysInMonth(month + 1, year);
          let arr = daysInArray(totalDays);
          let sendArr = [];
          let getArr = [];

          for (let i = 0; i < arr.length; i++) {
            let val = res.data.result.list.find(l => l.day == arr[i]);
            if (val && val.hasOwnProperty('transferFrom')) {
              getArr.push(Number(val.transferFrom));
            } else {
              getArr.push(0);
            }
            if (val && val.hasOwnProperty('transferTo')) {
              sendArr.push(Number(val.transferTo));
            } else {
              sendArr.push(0);
            }
          }
          let mtdChart = subsMTDWalletTrans({
            daysArr: arr, 
            getArr: getArr,
            sendArr: sendArr
          });
          props.showSubsMTDWalletTrans(mtdChart);
          props.showSubsMTDWalletTransLoader(false);
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching charts';
          notifyFailed(errorMsg);
          props.showSubsMTDWalletTransLoader(false);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching charts';
          notifyFailed(errorMsg);
          props.showSubsMTDWalletTransLoader(false);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        props.showSubsMTDWalletTransLoader(false);
      }
    }).catch(err => {
      notifyFailed('Internal Server Error');
      props.showSubsMTDWalletTransLoader(false);
    });
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Available Balance</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i class="fas fa-money-bill-alt"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? 
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> : 
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(totalAmt)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>
              </Col>
            
              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Bank WithDrawals</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i class="fas fa-university"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? 
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> : 
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(bankWithDraw)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>
              </Col>
            

              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>MMO WithDrawals</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i class="fas fa-money-check-alt"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? 
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> : 
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(mmoWithDraw)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>
              </Col>

            </Row>
            
            <Row className="mt-3">

              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Payments</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                          <i class="fas fa-money-bill-wave"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? 
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> : 
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(merchantPayment)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>
              </Col>
            

              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Wallet Transfer</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <i class="fas fa-envelope-open-text"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? 
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> : 
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(transferAmt)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>
              </Col>
            

              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Wallet Received</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-purple text-white rounded-circle shadow">
                          <i class="fas fa-wallet"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? 
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> : 
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(receivedAmt)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>
              </Col>
            
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default SubscriberHeader;