import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
} from "reactstrap";
import ProfileDetails from "./profile-details";
import VerifyUser from "./verify-user";
import { useToasts } from "react-toast-notifications";
import {
  getMethodDefaultToken,
  postMethodDefaultToken,
} from "../../services/httpServices";
import BankDetails from "./bank-details";
import MMODetails from "./mmo-details";

const SignupSubscriber = () => {
  const [profileDetails, setProfileDetails] = useState({});
  const [page, setPage] = useState(1);
  const [banks, setBanks] = React.useState(null);
  const [mobileMoney, setMMO] = React.useState(null);
  const { addToast } = useToasts();

  useEffect(() => {
    fetchBank();
    fetchMMO();
  }, []);

  const userDetailCB = (data, page = null) => {
    setProfileDetails(data);
    setPage(page ?? 2);
  };

  const otpVerified = () => {
    setPage(3);
  };

  const bankDetailCB = (data) => {
    setProfileDetails(data);
    // setPage(4);
  };

  const mmoDetailCB = (data) => {
    setProfileDetails(data);
    setPage(4);
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const fetchBank = async () => {
    let errorMsg = "";
    getMethodDefaultToken(
      "/api/bankProfile/ddllist?Accounttype=Collection",
      "get"
    )
      .then((res) => {
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setBanks(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        notifyFailed("Internal Server Error");
      });
  };

  const fetchMMO = async () => {
    let errorMsg = "";
    getMethodDefaultToken("/api/MobileMoney/ddllist", "get")
      .then((res) => {
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setMMO(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        notifyFailed("Internal Server Error");
      });
  };

  return (
    <Col lg="6" md="7" mt="5">
      <Card className=" shadow border-0">
        <CardBody style={{ minHeight: "300px" }}>
          <Row className="align-items-center mb-4">
            <Col lg="12">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
                onClick={() => (window.location.href = "/")}
              >
                <img
                  alt="..."
                  src={require("../../assets/img/brand/lapologo.PNG").default}
                  style={{
                    width: "120px",
                    objectFit: "contain",
                    height: "auto",
                    cursor: "pointer",
                  }}
                />
              </div>
              <h3 className="mb-0 text-center text-dark">
                Register Subscriber
              </h3>
            </Col>
          </Row>
          {page == 1 && (
            <ProfileDetails
              parentCallback={userDetailCB}
              userDetails={profileDetails}
            />
          )}
          {page == 2 && (
            <VerifyUser
              {...profileDetails}
              parentCallback={otpVerified}
              goBack={() => {
                setPage(1);
              }}
            />
          )}
          {page == 3 && (
            <BankDetails
              parentCallback={bankDetailCB}
              userDetails={{ ...profileDetails }}
              banks={banks}
              mmo={mobileMoney}
              goBack={() => {
                setPage(1);
              }}
            />
          )}
          {page == 4 && (
            <MMODetails
              parentCallback={mmoDetailCB}
              userDetails={profileDetails}
              mmo={mobileMoney}
              banks={banks}
              goBack={() => {
                setPage(3);
              }}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default SignupSubscriber;
