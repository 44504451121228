import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Row,
  Col,
  Container,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
  Button,
  Modal,
  ModalBody,
  Label,
} from "reactstrap";
import classnames from "classnames";
import { useToasts } from "react-toast-notifications";
import { checkSpace, getMethod, postMethod, postMethodDefaultToken } from "./../services/httpServices";

import Header from "components/Headers/Header.js";
import PreviewSubscriber from "./preview-subscriber";
import {
  AUTH_MODE,
  AMOUNT_HINT,
  PIN_HINT,
  PASSWORD_HINT,
  MOBILE_ONLY_COUNTRY,
} from "appConfig";
import {
  subscriberType,
  checkBankAccNo,
  checkName,
  checkBbanValidation,
} from "services/util";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const codes = [
  23225, 23230, 23231, 23232, 23233, 23234, 23244, 23275, 23276, 23277, 23278,
  23279, 23280, 23288, 23299,
];

const MODES = AUTH_MODE;

const initialValue = {
  firstName: "",
  lastName: "",
  mobileCode: 232,
  phone: "",
  mobWithoutCC: "",
  email: "",
  mobileMoney: "",
  mobileMoneyAccNo: "",
  bank: "",
  bban: "",
  bankLinkedMobileCode: 0,
  bankLinkedPhone: 0,
  bankMobWithoutCC: 0,
  password: "",
  oneFAAmt: "50000",
  authMode: "1FA",
  pin: "",
  subscriberType: "",
  organisationName: "",
  bankAccNo: "",
  number:  '',
  verified: false,
  loading: false,
  show: "No",
  isAccountVerified: false

};

const resetValues = {
  firstName: "",
  lastName: "",
  mobileCode: 232,
  phone: "",
  mobWithoutCC: "",
  email: "",
  mobileMoneyAccNo: "",
  bban: "",
  bankLinkedMobileCode: 0,
  bankLinkedPhone: 0,
  bankMobWithoutCC: 0,
  password: "",
  authMode: "",
  oneFAAmt: "",
  pin: "",
  bankAccNo: "",
  number:  '',
  verified: false,
  loading: false,
  show: "No",
  isAccountVerified: false
};

const navItemCls = {
  minWidth: "120px",
  textAlign: "center",
};

const cardHeaderCls = {
  backgroundColor: "#00984c",
};

const HINT = AMOUNT_HINT;

const AddSubscriber = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [values, setValues] = React.useState({...initialValue, isAccountVerified: false, 
    number:  '',
    verified: false,
    loading: false,
    show: "No",
  });
  const [showPswd, setShowPswd] = React.useState(false);
  const [showPin, setShowPin] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [disableTab2, setDisableTab2] = React.useState(true);
  const [disableTab3, setDisableTab3] = React.useState(true);
  const [banks, setBanks] = React.useState(null);
  const [mobileMoney, setMMO] = React.useState(null);
  const [loading, setLoading] = React.useState(false)
  const { addToast } = useToasts();
  const [acno,setAcno] = useState('')
  React.useEffect(() => {
    fetchBank();
    fetchMMO();
  }, []);

  // React.useEffect(() => {
  //   if (mobileMoney && mobileMoney.length) {
  //     setValues({ ...values, 'mobileMoney': mobileMoney[0].key });
  //   }
  // }, [mobileMoney])

  // React.useEffect(() => {
  //   if (banks && banks.length) {
  //     setValues({ ...values, 'bank': banks[0].key });
  //   }
  // }, [banks])

  React.useEffect(() => {
    if (values.subscriberType == "Organisation") {
      setValues({ ...values, firstName: "", lastName: "" });
    } else if (values.subscriberType == "Individual") {
      setValues({ ...values, organisationName: "" });
    }
  }, [values.subscriberType]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleInputChange = (e) => {
    var { name, value } = e.target;

    // value = value.trim();
    if (checkSpace(value)) {
      if (name === "bankLinkedPhone") {
        if (checkMobValidation(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === "firstName") {
        if (checkName(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === "lastName") {
        if (checkName(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === "organisationName") {
        if (checkName(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === "bban") {
        if (checkBbanValidation(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === "mobileMoneyAccNo") {
        if (checkMobileMoneyAccNo(value)) {
          setValues({ ...values, [name]: value });
        }
        // } else if (name === 'password') {
        //   if (pswdValidation(value)) {
        //     setValues({ ...values, [name]: value });
        //   }
      } else if (name === "pin") {
        if (pinValidation(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === "oneFAAmt") {
        if (checkDigitOnly(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === "bankAccNo") {
        // if (checkBankAccNo(value)) {
          setValues({ ...values, [name]: value });
        // }
      } else {
        setValues({ ...values, [name]: value });
      }
    }
  };

  const phoneChange = (value, data, event, formattedValue) => {
    if (data.dialCode && data.dialCode.length) {
      setValues({
        ...values,
        phone: value,
        mobileCode: data.dialCode,
        mobWithoutCC: value.substring(data.dialCode.length),
      });
    }
    return;
  };

  const bankPhoneChange = (value, data, event, formattedValue) => {
    if (data.dialCode && data.dialCode.length) {
      setValues({
        ...values,
        bankLinkedPhone: value,
        bankLinkedMobileCode: data.dialCode,
        bankMobWithoutCC: value.substring(data.dialCode.length),
      });
    }

    return;
  };

  const closeModal = () => {
    setOpen(false);
    // setValues({
    //   ...resetValues,
    //   'mobileMoney': "",
    //   'bank': ""
    // });
    setValues({ ...initialValue });
    setActiveTab("1");
    setDisableTab2(true);
    setDisableTab3(true);
  };

  const reviewModal = () => {
    setOpen(false);
    // setActiveTab('1');
  };

  const checkMobValidation = (value) => {
    let digiRegex = /^[0-9]*$/; 
    if (value.length <= 8) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkMobileMoneyAccNo = (value) => {
    // let digiRegex = /^[0-9]*$/;
    if (value.length <= 11) {
      // if (digiRegex.test(value)) {
      //   return true;
      // } else {
      //   return false;
      // }
      return true;
    } else {
      return false;
    }
  };

  const pswdValidation = (value) => {
    if (value.length <= 6) {
      return true;
    } else {
      return false;
    }
  };

  const pinValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 4) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkProfileTab = () => {
    let error = {};
    let valid = true;
    if (!values.subscriberType) {
      error.subscriberType = "Please select subscriber type";
      valid = false;
    }
    if (values.subscriberType === "Individual") {
      if (!values.firstName) {
        error.firstName = "First Name is Required";
        valid = false;
      }
      if (!values.lastName) {
        error.lastName = "Last Name is Required";
        valid = false;
      }
    } else if (values.subscriberType === "Organisation") {
      if (!values.organisationName) {
        error.organisationName = "Organsation Name is Required";
        valid = false;
      }
    }
    if (!values.mobileCode) {
      error.mobileCode = "Mobile Code is Required";
      valid = false;
    }
    if (values.phone && values.phone.length < 11) {
      error.phone = "Phone Number is Required [min 8 digit + country code]";
      valid = false;
    } else if (!values.mobWithoutCC) {
      error.phone = "Phone Number is Required";
      valid = false;
    }
    if (!values.password) {
      error.password = "Password is Required";
      valid = false;
    } else if (values.password.length < 6) {
      error.password = "Password should have atleast 6 characters";
      valid = false;
    }

    if (!values.pin) {
      error.pin = "PIN is Required";
      valid = false;
    } else if (values.pin.length !== 4) {
      error.pin = "PIN should have 4 characters";
      valid = false;
    }
    if (values.email) {
      if (!checkEmail(values.email)) {
        error.email = "Please enter valid email";
        valid = false;
      }
    }
    setErrors(error);
    return valid;
  };

  const checkEmail = (email) => {
    let emailRegEx = /\S+@\S+\.\S+/;
    if (emailRegEx.test(email)) {
      return true;
    } else {
      return false;
    }
  };

  const validateProfile = () => {
    if (checkProfileTab()) {
      toggle("3");
      setDisableTab2(false);
      setValues({ ...values, bankAccNo: "", bankLinkedPhone: 0 });
    } else {
      setDisableTab2(true);
      setDisableTab3(true);
    }
  };

  const checkMMTab = () => {
    let error = {};
    let valid = true;

    if (values.mobileMoney) {
      if (!values.mobileMoneyAccNo) {
        error.mobileMoneyAccNo = "Mobile Money Account Number is Required";
        valid = false;
      }
      // if (values.mobileMoneyAccNo.length !== 11) {
      //   error.mobileMoneyAccNo = 'Mobile Money Account Number should have 11 digits';
      //   valid = false;
      // }
    } else if (values.mobileMoneyAccNo) {
      if (!values.mobileMoney) {
        error.mobileMoney = "Mobile Money Account is Required";
        valid = false;
      }
      // if (values.mobileMoneyAccNo.length !== 11) {
      //   error.mobileMoneyAccNo = 'Mobile Money Account Number should have 11 digits';
      //   valid = false;
      // }
    }
    // if (!values.mobileMoney) {
    //   error.mobileMoney = 'Mobile Money Account is Required';
    //   valid = false;
    // }
    // if (!values.mobileMoneyAccNo) {
    //   error.mobileMoneyAccNo = 'Mobile Money Account Number is Required';
    //   valid = false;
    // }
    // if (values.mobileMoneyAccNo.length !== 11) {
    //   error.mobileMoneyAccNo = 'Mobile Money Account Number should have 11 digits';
    //   valid = false;
    // }
    setErrors(error);
    return valid;
  };

  const validateMM = () => {
    if (checkMMTab()) {
      toggle("3");
      setDisableTab3(false);
    } else {
      setDisableTab3(true);
    }
  };

  const checkBank = () => {
    let error = {};
    let valid = true;
    if (!values.bank) {
      error.bank = "Bank is Required";
      valid = false;
    }
    // if (!values.bban) {
    //   error.bban = 'Bban Number is Required';
    //   valid = false;
    // } else if (values.bban.length < 8 || values.bban.length > 18) {
    //   error.bban = 'Bban Number should have 8 to 18 digits';
    //   valid = false;
    // }
    // if (values.bban.length !== 0 && (values.bban.length < 8 || values.bban.length > 18)) {
    //   error.bban = 'Bban Number should have 8 to 18 digits';
    //   valid = false;
    // }

    // if (!values.bankLinkedMobileCode) {
    //   error.bankLinkedMobileCode = 'Mobile Code is Required';
    //   valid = false;
    // }
    // if (!values.bankLinkedPhone) {
    //   error.bankLinkedPhone = 'Phone number is Required';
    //   valid = false;
    // } else if (!values.bankMobWithoutCC) {
    //   error.bankLinkedPhone = 'Phone number is Required';
    //   valid = false;
    // }

    if (!values.authMode) {
      error.authMode = "Please select authentication mode";
      valid = false;
    }

    if (values.authMode == "1FA" && values.oneFAAmt == "") {
      error.oneFAAmt = "Please enter amount";
      valid = false;
    }

    if (!acno) {
      error.bankAccNo = "Please enter bank account number";
      valid = false;
    } 
    // else if (acno.length < 8 || acno.length > 18) {
    //   error.bankAccNo = "Please enter 8 to 18 digit Bank Account Number";
    //   valid = false;
    // }
    setErrors(error);
    return valid;
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const validateBank = () => {
    if (checkBank()) {
      setValues({...values, bankAccNo: acno})
      setOpen(true);
    }
  };

  const fetchBank = async () => {
    let errorMsg = "";
    getMethod("/api/bankProfile/ddllist?Accounttype=Collection", "get")
      .then((res) => {
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setBanks(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        notifyFailed("Internal Server Error");
      });
  };

  const fetchMMO = async () => {
    let errorMsg = "";
    getMethod("/api/MobileMoney/ddllist", "get")
      .then((res) => {
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setMMO(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        notifyFailed("Internal Server Error");
      });
  };

  const checkDigitOnly = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (digiRegex.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  const onVerifyAccount = () => {
    let error = {};
    let valid = true;
    if (!acno) {
      error.bankAccNo = "Please enter account number";
      valid = false;
    } 
    // else if (acno.length < 8 || acno.length > 18) {
    //   error.bankAccNo = "Please enter 8 to 18 digit Bank Account Number";
    //   valid = false;
    // } 
    else if (!values.bank) {
      error.bank = "Bank is Required";
      valid = false;
    }

    if (!valid) {
      setErrors(error);
      return;
    } else {
      setErrors({});
    }
    const data = {
      id: acno,
    };
    setLoading(true)
    postMethod("/api/Subscriber/VerifyLapoAccount", data, "post")
      .then((res) => {
        setLoading(false)
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            setValues({ ...values, isAccountVerified: true });
            notifySuccess();
          } else if (res.data.responseCode === "400") {
            // Error
            let errorMsg =
              "Error in Verifying account. Please contact Lapo Wallet team at 3737";
            setErrors({ bankAccNo: errorMsg });
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            let errorMsg = "Unknown Error in Verifying details";
            notifyFailed(errorMsg);
          }
        } else {
          let errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false)
        notifyFailed("Internal Server Error");
      });
  };

  const onVerifyNIN = () => {
    setValues({ ...values, loading: true });
    postMethodDefaultToken(
      "/api/Subscriber/VerifyNIN",
      { nin: values.number },
      "post"
    )
      .then((res) => {
        if (!res?.data?.status) {
          notifyFailed(res?.data?.message || "Invalid NIN");
          setValues({ ...values, loading: false });
        } else {
          const data = res?.data?.data;
          setValues({
            ...values,
            ...data,
            phone: 232 + data?.phoneNumber,
            mobWithoutCC: data?.phoneNumber,
            verified: true,
            loading: false,
            organisationName: data?.firstName + " " + data?.lastName
          });
        }
      })
      .catch((err) => {
        setValues({ ...values, loading: false });
        console.log({ err });
      });
  };

  return (
    <div>
      <Header />
      <Container className="mt--7" fluid>
        <Modal isOpen={open} style={{ width: "70vw", maxWidth: "70vw" }}>
          <ModalBody style={{ padding: 0 }}>
            <PreviewSubscriber
              closeModal={closeModal}
              reviewModal={reviewModal}
              {...values}
              bankList={banks}
              mobileMoneyList={mobileMoney}
            />
          </ModalBody>
        </Modal>
        <Card>
          <CardBody>
            <Nav tabs>
              <NavItem style={navItemCls}>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                  style={{ padding: "10px" }}
                >
                  Profile
                </NavLink>
              </NavItem>
              <NavItem style={navItemCls}>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => {
                    toggle("3");
                  }}
                  style={{ padding: "10px" }}
                  disabled={disableTab3}
                >
                  Bank
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div class="card-border">
                  <Card>
                    <CardHeader style={cardHeaderCls}>
                      <Row className="align-items-center">
                        <Col lg="12">
                          <h3 className="mb-0 text-center text-white">
                            Profile Info
                          </h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <div>
                        <FormGroup tag="fieldset">
                          <strong>Subscriber Type</strong>
                          <Row>
                            <Col lg="6">
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="subscriberType"
                                    value="Individual"
                                    checked={
                                      values.subscriberType === "Individual"
                                    }
                                    onChange={handleInputChange}
                                  />{" "}
                                  Individual
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="subscriberType"
                                    value="Organisation"
                                    checked={
                                      values.subscriberType === "Organisation"
                                    }
                                    onChange={handleInputChange}
                                  />{" "}
                                  Organisation
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </FormGroup>
                        {values?.subscriberType && (
                               <FormGroup tag="fieldset">
                               <strong>Continue with NIN?</strong>
                               <Row>
                                 <Col lg="3">
                                   <FormGroup check>
                                     <Label check>
                                       <Input
                                         type="radio"
                                         name="show"
                                         value="Yes"
                                         checked={
                                           values.show === "Yes"
                                         }
                                         onChange={(e) => {
                                          handleInputChange(e)
                                         }}
                                       />{" "}
                                       Yes
                                     </Label>
                                   </FormGroup>
                                 </Col>
                                 <Col lg="6">
                                   <FormGroup check>
                                     <Label check>
                                       <Input
                                         type="radio"
                                         name="show"
                                         value="No"
                                         checked={
                                           values.show === "No"
                                         }
                                         onChange={() => {
                                          setValues({...initialValue, subscriberType: values.subscriberType})
                                         }}
                                       />{" "}
                                       No
                                     </Label>
                                   </FormGroup>
                                 </Col>
                               </Row>
                             </FormGroup>
                        )}
                        {values.subscriberType && (
                          <div>
                            {values.show === "Yes" && (
                              <Row>
                                <Col lg="4">
                                  <FormGroup tag={"fieldset"}>
                                  <label
                                        className="form-control-label"
                                        htmlFor="input-nin  "
                                      >
                                        NIN
                                      </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="input-nin"
                                      placeholder="NIN"
                                      type="text"
                                      name="nin"
                                      value={values.number}
                                      disabled={
                                        values.loading || values.verified
                                      }
                                      onChange={(e) =>
                                        setValues({
                                          ...values,
                                          number: e.target.value,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                  <Button
                                    style={{
                                      float: "right",
                                      marginTop: "-8px",
                                      marginBottom: "8px",
                                    }}
                                    onClick={onVerifyNIN}
                                    disabled={
                                      !values.number ||
                                      values.loading ||
                                      values.verified
                                    }
                                  >
                                    {values.loading
                                      ? "Loading..."
                                      : values?.verified
                                      ? "Verified"
                                      : "Verify"}
                                  </Button>
                                </Col>
                              </Row>
                            )}
                            <Row>
                              {values.subscriberType == "Individual" && (
                                <>
                                  <Col lg="4">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-fname"
                                      >
                                        First Name
                                      </label>
                                      <Input
                                        className="form-control-alternative"
                                        id="input-fname"
                                        placeholder="First Name"
                                        type="text"
                                        name="firstName"
                                        value={values.firstName}
                                        disabled={
                                          values.loading || values.verified
                                        }
                                        onChange={handleInputChange}
                                      />
                                      {errors.firstName && (
                                        <div className="text-left text-danger">
                                          <small>* {errors.firstName}</small>
                                        </div>
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col lg="4">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-lname"
                                      >
                                        Last Name
                                      </label>
                                      <Input
                                        className="form-control-alternative"
                                        id="input-lname"
                                        placeholder="Last Name"
                                        type="text"
                                        name="lastName"
                                        disabled={
                                          values.loading || values.verified
                                        }
                                        value={values.lastName}
                                        onChange={handleInputChange}
                                      />
                                      {errors.lastName && (
                                        <div className="text-left text-danger">
                                          <small>* {errors.lastName}</small>
                                        </div>
                                      )}
                                    </FormGroup>
                                  </Col>
                                </>
                              )}

                              {values.subscriberType == "Organisation" && (
                                <Col lg="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-oname"
                                    >
                                      Organisation Name
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="input-oname"
                                      placeholder="Organisation Name"
                                      type="text"
                                      name="organisationName"
                                      value={values.organisationName}
                                      onChange={handleInputChange}
                                    />
                                    {errors.organisationName && (
                                      <div className="text-left text-danger">
                                        <small>
                                          * {errors.organisationName}
                                        </small>
                                      </div>
                                    )}
                                  </FormGroup>
                                </Col>
                              )}
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-email"
                                  >
                                    Email
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-email"
                                    placeholder="jesse@example.com"
                                    type="text"
                                    name="email"
                                    value={values.email}
                                    onChange={handleInputChange}
                                    autoComplete="off"
                                  />
                                  {errors.email && (
                                    <div className="text-left text-danger">
                                      <small>* {errors.email}</small>
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>

                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-pswd"
                                  >
                                    PIN
                                  </label>
                                  <InputGroup className="input-group-alternative">
                                    <Input
                                      className="form-control-alternative"
                                      id="input-pswd"
                                      type={showPin ? "password" : "text"}
                                      name="pin"
                                      placeholder="Enter 4-digit Pin"
                                      value={values.pin}
                                      onChange={handleInputChange}
                                      autoComplete="off"
                                    />
                                    {!showPin && (
                                      <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                          <i
                                            className="fa fa-eye-slash"
                                            onClick={() => setShowPin(true)}
                                          />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                    )}

                                    {showPin && (
                                      <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                          <i
                                            className="fa fa-eye"
                                            onClick={() => setShowPin(false)}
                                          />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                    )}
                                  </InputGroup>
                                  *{" "}
                                  <small>
                                    <i>{PIN_HINT}</i>
                                  </small>
                                  {errors.pin && (
                                    <div className="text-left text-danger">
                                      <small>* {errors.pin}</small>
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-pswd"
                                  >
                                    Phone Number
                                  </label>
                                  <PhoneInput
                                    className="form-control-alternative"
                                    country={"sl"}
                                    enableSearch
                                    value={values.phone}
                                    // onlyCountries={MOBILE_ONLY_COUNTRY}
                                    countryCodeEditable={false}
                                    onChange={phoneChange}
                                    disableCountryCode={
                                      values.loading || values.verified
                                    }
                                    disableDropdown={
                                      values.loading || values.verified
                                    }
                                    disableSearchIcon={
                                      values.loading || values.verified
                                    }
                                    disableCountryGuess={
                                      values.loading || values.verified
                                    }
                                    disabled={values.loading || values.verified}
                                    name="phone"
                                  />
                                  {errors.phone && (
                                    <div className="text-left text-danger">
                                      <small>* {errors.phone}</small>
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-pswd"
                                  >
                                    Password
                                  </label>
                                  <InputGroup className="input-group-alternative">
                                    <Input
                                      className="form-control-alternative"
                                      id="input-pswd"
                                      type={showPswd ? "password" : "text"}
                                      name="password"
                                      value={values.password}
                                      onChange={handleInputChange}
                                      autoComplete="off"
                                    />
                                    {!showPswd && (
                                      <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                          <i
                                            className="fa fa-eye-slash"
                                            onClick={() => setShowPswd(true)}
                                          />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                    )}

                                    {showPswd && (
                                      <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                          <i
                                            className="fa fa-eye"
                                            onClick={() => setShowPswd(false)}
                                          />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                    )}
                                  </InputGroup>
                                  *{" "}
                                  <small>
                                    <i>{PASSWORD_HINT}</i>
                                  </small>
                                  {errors.password && (
                                    <div className="text-left text-danger">
                                      <small>* {errors.password}</small>
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>

                              <Col lg-6>
                                <FormGroup>
                                  <Button
                                    className="btn float-right mt-4"
                                    color="primary"
                                    type="submit"
                                    onClick={validateProfile}
                                  >
                                    Save and Next
                                  </Button>
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </TabPane>
              {/* <TabPane tabId="2">
                <div class="card-border">
                  <Card>
                    <CardHeader style={cardHeaderCls}>
                      <Row className="align-items-center">
                        <Col lg="12">
                          <h3 className="mb-0 text-center text-white">Mobile Money Info</h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              for="input-mobilemoney"
                              className="form-control-label">
                              Mobile Money (optional)
                            </label>
                            <Input
                              type="select"
                              id="input-mobilemoney"
                              className="form-control-alternative"
                              name="mobileMoney"
                              value={values.mobileMoney}
                              onChange={handleInputChange}
                            >
                              <option key="" value="">------Select MMO------</option>
                              {mobileMoney && mobileMoney.map(c => <option key={c.key} value={c.key}>
                                {c.value}</option>)}
                            </Input>
                            {errors.mobileMoney && <div className="text-left text-danger">
                              <small>* {errors.mobileMoney}</small>
                            </div>}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-mmaccno"
                            >
                              MM Account Number (optional)
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-mmaccno"
                              placeholder="Acount Number (optional)"
                              type="text"
                              name="mobileMoneyAccNo"
                              value={values.mobileMoneyAccNo}
                              onChange={handleInputChange}
                            />
                            {errors.mobileMoneyAccNo && <div className="text-left text-danger">
                              <small>* {errors.mobileMoneyAccNo}</small>
                            </div>}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <div>
                            <Button className="btn float-right"
                              color="primary" type="submit"
                              onClick={validateMM}>
                              Save and Next
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                </div>
              </TabPane> */}
              <TabPane tabId="3">
                <div class="card-border">
                  <Card>
                    <CardHeader style={cardHeaderCls}>
                      <Row className="align-items-center">
                        <Col lg="12">
                          <h3 className="mb-0 text-center text-white">
                            Bank Info
                          </h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              for="input-bank"
                              className="form-control-label"
                            >
                              Bank
                            </label>
                            <Input
                              type="select"
                              id="input-bank"
                              className="form-control-alternative"
                              name="bank"
                              value={values?.bank}
                              onChange={handleInputChange}
                            >
                              <option key="" value="">
                                ------Select Bank------
                              </option>
                              {banks &&
                                banks.map((c) => (
                                  <option key={c.key} value={c.key}>
                                    {c.value}
                                  </option>
                                ))}
                            </Input>
                            {errors.bank && (
                              <div className="text-left text-danger">
                                <small>* {errors.bank}</small>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        {/* <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-bban"
                            >
                              BBAN Number (optional)
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-bban"
                              placeholder="BBAN Number (optional)"
                              type="text"
                              name="bban"
                              value={values.bban}
                              onChange={handleInputChange}
                            />
                            {errors.bban && <div className="text-left text-danger">
                              <small>* {errors.bban}</small>
                            </div>}
                          </FormGroup>
                        </Col> */}

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-bank-accno"
                            >
                              Bank Account Number
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-bank-accno"
                              placeholder="Bank Account Number"
                              type="text"
                              name="bankAccNo"
                              disabled={values.isAccountVerified}
                              value={acno}
                              onChange={(e) => setAcno(e.target.value)}
                            />
                            {errors.bankAccNo && (
                              <div className="text-left text-danger">
                                <small>* {errors.bankAccNo}</small>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                          <Col lg="12">
                            <Button
                              className="text-white float-right"
                              color="primary"
                              type="button"
                              onClick={onVerifyAccount}
                              disabled={loading || values.isAccountVerified}
                            >
                              Verify Account
                              {loading && <i class="fa fa-spinner fa-spin"></i>}
                            </Button>
                          </Col>

                        {/* <Col lg="2">
                          <FormGroup>
                            <label
                              for="input-bankLinkedMobileCode"
                              className="form-control-label">
                              Mobile Code
                            </label>
                            <Input
                              type="select"
                              id="input-bankLinkedMobileCode"
                              className="form-control-alternative"
                              name="bankLinkedMobileCode"
                              value={values.bankLinkedMobileCode}
                              onChange={handleInputChange}
                            >
                              {codes.map(c => <option key={c}>{c}</option>)}
                            </Input>
                            {errors.bankLinkedMobileCode && <div className="text-left text-danger">
                              <small>* {errors.bankLinkedMobileCode}</small>
                            </div>}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-bankLinkedPhone"
                            >
                              Bank Linked Phone Number
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-bankLinkedPhone"
                              placeholder="Phone Number"
                              type="text"
                              name="bankLinkedPhone"
                              value={values.bankLinkedPhone}
                              onChange={handleInputChange}
                            />
                            {errors.bankLinkedPhone && <div className="text-left text-danger">
                              <small>* {errors.bankLinkedPhone}</small>
                            </div>}
                          </FormGroup>
                        </Col>
 */}

                        {/* <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-bankLinkedPhone"
                            >
                              Bank Linked Phone Number
                            </label>
                            <PhoneInput
                              className="form-control-alternative"
                              country={'sl'}
                              value={values.bankLinkedPhone}
                              onlyCountries={MOBILE_ONLY_COUNTRY}
                              countryCodeEditable={false}
                              onChange={bankPhoneChange}
                            />
                            {errors.bankLinkedPhone && <div className="text-left text-danger">
                              <small>* {errors.bankLinkedPhone}</small>
                            </div>}
                          </FormGroup>
                        </Col> */}
                        <Col lg="6">
                          <FormGroup>
                            <label
                              for="input-authmode"
                              className="form-control-label"
                            >
                              Authentication Mode
                            </label>
                            <Input
                              type="text"
                              id="input-authmode"
                              className="form-control-alternative"
                              name="authMode"
                              value={values.authMode}
                              onChange={handleInputChange}
                              disabled
                            />
                            {/* <option key="">----Select Mode-----</option>
                              {MODES.map((c) => (
                                <option key={c}>{c.value}</option>
                              ))}
                            </Input> */}
                            {errors.authMode && (
                              <div className="text-left text-danger">
                                <small>* {errors.authMode}</small>
                              </div>
                            )}
                          </FormGroup>
                        </Col>

                        {values.authMode == "1FA" && (
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-1FA"
                              >
                                Enter amount for using 1FA
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-1FA"
                                placeholder="Amount"
                                type="text"
                                name="oneFAAmt"
                                value={values.oneFAAmt}
                                onChange={handleInputChange}
                                disabled
                              />

                              <div className="text-left">
                                <i>
                                  <small>* {HINT}</small>
                                </i>
                              </div>
                              {errors.oneFAAmt && (
                                <div className="text-left text-danger">
                                  <small>* {errors.oneFAAmt}</small>
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col lg-12>
                          <div>
                            <Button
                              className="btn float-right"
                              color="primary"
                              type="submit"
                              onClick={() => {
                                setValues({...values, bankAccNo: acno})
                                setTimeout(() => {
                                  validateBank()
                                }, 10)
                              }}
                              disabled={loading || !values.isAccountVerified}
                            >
                              Save and Review
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default AddSubscriber;
