import { getLoginType } from "./httpServices";

export const setUserDetails = (props) => {
  const initialValue = {
    'firstName': props.userDetails && props.userDetails.firstName ? 
    props.userDetails.firstName.trim() : '',
    'lastName': props.userDetails && props.userDetails.lastName ? 
    props.userDetails.lastName.trim() : '',
    'organisationName': props.userDetails.organisationName ? 
    props.userDetails.organisationName.trim() : '',
    'subscriberType': props.userDetails.subscriberType ? 
    props.userDetails.subscriberType : '',
    'mobileCode': props.userDetails && props.userDetails.mobileCode ? 
    props.userDetails.mobileCode : 232,
    'phone': props.userDetails && props.userDetails.phone ? 
    props.userDetails.phone : '',
    'mobWithoutCC': props.userDetails && props.userDetails.mobWithoutCC ? 
    props.userDetails.mobWithoutCC : '',
    'email': props.userDetails && props.userDetails.email ? 
    props.userDetails.email : '',
    'password': props.userDetails && props.userDetails.password ? 
    props.userDetails.password : '',
    'oneFAAmt': props.userDetails && props.userDetails.oneFAAmt ? 
    props.userDetails.oneFAAmt : '50000',
    'authMode': props.userDetails && props.userDetails.authMode ? 
    props.userDetails.authMode : '1FA',
    'confirmpswd': props.userDetails && props.userDetails.confirmpswd ? 
    props.userDetails.confirmpswd : '',
    'bank': props.userDetails && props.userDetails.bank ? props.userDetails.bank : '',
    'bban': props.userDetails && props.userDetails.bban ? props.userDetails.bban : '',
    'bankLinkedMobileCode': props.userDetails && props.userDetails.bankLinkedMobileCode ?
    props.userDetails.bankLinkedMobileCode : 232,
    'bankLinkedPhone': props.userDetails && props.userDetails.bankLinkedPhone ?
    props.userDetails.bankLinkedPhone : '',
    'bankMobWithoutCC': props.userDetails && props.userDetails.bankMobWithoutCC ? 
    props.userDetails.bankMobWithoutCC : '',
    'mobileMoney': props.userDetails && props.userDetails.mobileMoney ? props.userDetails.mobileMoney : '',
    'mobileMoneyAccNo': props.userDetails.mobileMoneyAccNo ? 
    props.userDetails.mobileMoneyAccNo : '',
    'pin': props.userDetails && props.userDetails.pin ? props.userDetails.pin : '',
    'confirmpin': props.userDetails && props.userDetails.confirmpin ? props.userDetails.confirmpin :
    '',
    'bankAccNo': props.userDetails && props.userDetails.bankAccNo ? props.userDetails.bankAccNo : props?.userDetails?.mobWithoutCC || '',
    number: props.userDetails && props.userDetails.number ? 
    props.userDetails.number.trim() : '',
    verified: props.userDetails && props.userDetails.verified,
    loading: props.userDetails && props.userDetails.loading,
    show: props.userDetails && props.userDetails.show || "No",
  };

  return initialValue;
};

export const getMobCodes = () => {
  return [23225, 23230, 23231, 23232,
    23233, 23234, 23244, 23275, 23276, 23277,
    23278, 23279, 23280, 23288, 23299];
}

export const checkMacAddress = (value) => {
  let regex = /^([0-9a-f]{1,2}[\.:-]){5}([0-9a-f]{1,2})$/i
  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
}

export const checkDigit2Decimal = (value) => {
  // let regex = /^\d+(\.\d{0,2})?$/;
  let regex = /^[0-9]*(\.\d{0,2})?$/;
  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
}

export const subscriberType = () => {
  return [
    {key: 'Individual', value: 'Individual'},
    {key: 'Organisation', value: 'Organisation'}
  ];
}

export const checkPercentage = (value) => {
  const regex = /^100(\.0{0,2}?)?$|^\d{0,2}(\.\d{0,2})?$/;
  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
}

export const getAmt = (amt) => {
  return Number(amt).toLocaleString();
}

export const getPaymentType = (type) => {
  if (type == 'topup') {
    return 'Top-Up';
  } else if (type == 'Merchant') {
    return 'Merchant Payment';
  } else {
    return type;
  }
}

export const checkDigitOnly = (value) => {
  let digiRegex = /^[0-9]*$/;
  if (digiRegex.test(value)) {
    return true;
  } else {
    return false;
  }
}

export const checkBankAccNo = (value) => {
  // let digiRegex = /^[0-9]*$/;
  if (value.length <= 18) {
    // if (digiRegex.test(value)) {
    //   return true;
    // } else {
    //   return false;
    // }
    return true
  } else {
    return false;
  }
}


export const checkMMOBankAccNo = (value) => {
  // let digiRegex = /^[0-9]*$/;
  // if (value.length <= 11) {
    // if (digiRegex.test(value)) {
    //   return true;
    // } else {
    //   return false;
    // }
    return true
  // } else {
  //   return false;
  // }
}

export const getSortCls = (fieldName) => {
  if (fieldName == "0") {
    return 'fas fa-sort fa-lg';
  } else if (fieldName == "1") {
    return 'fas fa-sort-up fa-lg';
  } else if (fieldName == "2") {
    return 'fas fa-sort-down fa-lg';
  }
}

export const reportloaderContainer = {
  position: 'absolute',
  top: '270px',
  left: 0,
  width: '100%',
  heigth: '100%',
  backgroundColor: '#F8F8F8AD',
  // display: 'none'
};

export const reportloader = {
  left: '40%',
  top: '50%',
  zIndex: '1000',
  position: 'absolute'
};

export const paymentType = [
  {key: 'Wallet', value: 'Wallet'},
  {key: 'Bank', value: 'Bank'}
];
export const bankPaymentType = [
  {key: 'topup', value: 'Top Up'},
  {key: 'Merchant', value: 'Merchant Payment'}
];
export const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
}

export const daysInArray = (num) => {
  let arr = [];
  for (let i = 1; i <= num; i++) {
    arr.push(i);
  }
  return arr;
}

export const MonthArraySM = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul","Aug", "Sep", "Oct", "Nov", "Dec"
];

export const MonthArrayCAP = [
  {key:0, value: "January"},
  {key:1, value: "February"},
  {key:2, value: "March"},
  {key:3, value: "April"},
  {key:4, value: "May"},
  {key:5, value: "June"},
  {key:6, value: "July"},
  {key:7, value: "August"},
  {key:8, value: "September"},
  {key:9, value: "October"},
  {key:10, value: "November"},
  {key:11, value: "December"}
];

export const YearArray = () => {
  const currentYear = new Date().getFullYear();
  let arr = [];
  for (let i = 2021; i <= currentYear; i++) {
    arr.push({key: i, value: i});
  }
  return arr;
}

export const checkName = (value) => {
  // let charRegex = /^[A-Za-z. ]*$/;
  // if (charRegex.test(value)) {
  //   return true;
  // } else {
  //   return false;
  // }
  return true
}

export const checkBbanValidation = (value) => {
  // let digiRegex = /^[0-9]*$/;
  if (value.length <= 18) {
    // if (digiRegex.test(value)) {
    //   return true;
    // } else {
    //   return false;
    // }
    return true
  } else {
    return false;
  }
}

export const DUMMY_USER = {
  "id": 8,
  "pin": null,
  "subscriberUID": "232326572835",
  "userProfileId": "28275f9f-1a96-4060-ac75-c1d9ed60cb56",
  "subscriberName": "Kavita Rana",
  "firstName": "Kavita",
  "lastName": "Rana",
  "organizationName": null,
  "subscriberType": "Individual",
  "mobileCode": 232,
  "mobileCodeId": 232,
  "mobileNumber": "326572835",
  "mobNumber": "326572835",
  "email": "kr@gmail.com",
  "isVerifiedByAdmin": true,
  "mmoId": 2,
  "mmoAccountNumber": "12345678908",
  "bankProfileId": 4,
  "bban": "345678909876545678",
  "isBankVerifiedAPI": false,
  "isBankVerified": true,
  "bbanLinkMobileNumber": "876576487654",
  "bbanLinkMobileCode": 232,
  "bankAccountNumber": "7328265876457",
  "accountCreateedBy": "Admin",
  "balanceAmount": 97500.00,
  "authFactorType": "2FA",
  "excidedPaymentAmount": 0.00,
  "mmoName": "Test MMO",
  "bankName": "SCB",
  "isBankApiAvailable": false,
  "isCardAssigned": false,
  "createdAt": "2021-08-10T08:28:53.6355799",
  "createdBy": null,
  "updatedAt": null,
  "updatedBy": null,
  "isDeleted": false,
  "isBlocked": false,
  "password": null
};

export const getPrivileges = (module) => {
  const role = getLoginType();
  const previleges = {
    'add': true,
    'view': true,
    'edit': true,
    'block': true,
    'unblock': true
  };
  if (role == 'Admin') {
    return previleges;
  } else {
    if (module == 'user') {
      return getUserPrivileges(role, previleges);
    } else if (module == 'mmo') {
      return getMMOPrivileges(role, previleges);
    } else if (module == 'bank') {
      return getBanksPrivileges(role, previleges);
    } else if (module == 'merchant') {
      return getMerchantPrivileges(role, previleges);
    } else if (module == 'subscriber') {
      return getSubscriberPrivileges(role, previleges);
    } else if (module == 'commission') {
      return getCommissionPrivileges(role, previleges);
    } else if (module == 'super_agent') {
      return getSuperAgentPrivileges(role, previleges);
    } else {
      return previleges;
    }
  }
};

export const getSuperAgentPrivileges = (role, privileges) => {
  privileges['unblock'] = false;
  if (role == 'Finance') {
    privileges['add'] = false;
    privileges['view'] = false;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if (role == 'Operations Officer') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if (role == 'Customer Support Supervisor') {
    privileges['add'] = false;
    privileges['view'] = false;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if (role == 'Audits and Compliance') {
    privileges['add'] = false;
    privileges['view'] = false;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if (role == 'Super Agent') {
    privileges['add'] = true;
    privileges['view'] = true;
    privileges['edit'] = true;
    privileges['block'] = true;
  } else if (role == 'Sub Agent') {
    privileges['add'] = true;
    privileges['view'] = true;
    privileges['edit'] = true;
    privileges['block'] = true;
  } else {
    privileges['add'] = false;
    privileges['view'] = false;
    privileges['edit'] = false;
    privileges['block'] = false;
  }
  return privileges;
}

export const getUserPrivileges = (role, privileges) => {
  privileges['unblock'] = false;
  if (role == 'Finance') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if (role == 'Operations Officer') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if (role == 'Customer Support Supervisor') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if (role == 'Audits and Compliance') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  }
  return privileges;
}

export const getMMOPrivileges = (role, privileges) => {
  privileges['unblock'] = false;
  if (role == 'Finance') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if (role == 'Operations Officer') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if (role == 'Customer Support Supervisor') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if (role == 'Audits and Compliance') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if(role === 'Operation supervisor') {
    privileges['add'] = true;
    privileges['view'] = true;
    privileges['edit'] = true;
    privileges['block'] = true;
    privileges['unblock'] = true;
  } else {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  }
  return privileges;
}

export const getBanksPrivileges = (role, privileges) => {
  privileges['unblock'] = false;
  if (role == 'Finance') {
    privileges['add'] = true;
    privileges['view'] = true;
    privileges['edit'] = true;
    privileges['block'] = true;
    privileges['unblock'] = true;
  } else if (role == 'Operations Officer') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if (role == 'Customer Support Supervisor') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if (role == 'Audits and Compliance') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  }
  return privileges;
}

export const getMerchantPrivileges = (role, privileges) => {
  privileges['unblock'] = false;
  if (role == 'Finance') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if (role == 'Operations Officer') {
    privileges['add'] = true;
    privileges['view'] = true;
    privileges['edit'] = true;
    privileges['block'] = true;
    privileges['unblock'] = true;
  } else if (role == 'Customer Support Supervisor') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if (role == 'Audits and Compliance') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if(role === 'Operation supervisor') {
    privileges['add'] = true;
    privileges['view'] = true;
    privileges['edit'] = true;
    privileges['block'] = true;
    privileges['unblock'] = true;
  } else {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  }
  return privileges;
}

export const getSubscriberPrivileges = (role, privileges) => {
  privileges['unblock'] = false;
  if (role == 'Finance') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if (role == 'Operations Officer') {
    privileges['add'] = true;
    privileges['view'] = true;
    privileges['edit'] = true;
    privileges['block'] = false;
  } else if (role == 'Customer Support Supervisor') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if (role == 'Audits and Compliance') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if (role == 'Operation supervisor') {
    privileges['add'] = true;
    privileges['view'] = true;
    privileges['edit'] = true;
    privileges['block'] = true;
  }
   else {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  }
  return privileges;
}

export const getCommissionPrivileges = (role, privileges) => {
  privileges['unblock'] = false;
  if (role == 'Finance') {
    privileges['add'] = true;
    privileges['view'] = true;
    privileges['edit'] = true;
    privileges['block'] = false;
  } else if (role == 'Operations Officer') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if (role == 'Customer Support Supervisor') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else if (role == 'Audits and Compliance') {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  } else {
    privileges['add'] = false;
    privileges['view'] = true;
    privileges['edit'] = false;
    privileges['block'] = false;
  }
  return privileges;
}

export const Transaction_Log_Action_Type = [
  {key : 1, value: 'Balance Transfer' , id: 'BalanceTransfer'},
  {key : 2, value: 'Cash Top Up' , id: 'CashTopUp'},
  {key : 3, value: 'Merchant Payment' , id: 'MerchantPayment'},
  {key : 4, value: 'Top Up' , id: 'topup'},
  {key : 5, value: 'Wallet Payment' , id: 'WalletPayment'},
  {key: 6, value: 'Bank Approved', id: 'BankApproved'},
  {key: 7, value: 'Bank Rejected', id: 'BankRejected'},
  {key: 7, value: 'Top Up Transfer', id: 'TopUpTransfer'},
  {key: 8, value: 'Bank Payment Transfer', id: 'BankPaymentTransfer'},
  {key: 9, value: 'Merchant Settlement', id: 'MerchantSettlement'},
  {key: 10, value: 'Bank Commission Transfer', id: 'BankCommissionTransfer'}
];

export const User_Log_Action_Type = [
  { key: 1, value: 'Add User' , id: 'AddUser'},
  { key: 2, value: 'Edit User', id: 'EditUser'},
  { key: 3, value: 'Block User', id: 'BlockedUser'},
  { key: 4, value: 'Unblock User', id: 'UnBlockedUser'},
  // { key: 5, value: 'Delete User', id: 'DeleteUser },
  { key: 6, value: 'Bank Verification', id: 'BankVerification'},
  { key: 7, value: 'Log in', id: 'Login'},
  { key: 8, value: 'Log out', id: 'LogOut'},
  { key: 9, value: 'Add Commission', id: 'AddCommission'},
  { key: 10, value: 'Edit Commission', id: 'EditCommission'},
  // { key: 11, value: 'Block Commission', id: 'BlockCommission'}
]

export const routePermissions = [
  {
    name: "Admin",
    layout: "/admin",
  },
  {
    name: "Bank",
    layout: "/bank",
  },
  {
    name: "Subscriber",
    layout: "/subscriber",
  },
  {
    name: "Merchant",
    layout: "/merchant",
  },
  {
    name: "Finance",
    layout: "/finance",
  },
  {
    name: "SettlementBank",
    layout: "/settlementBank",
  },
  {
    name: "Operations Officer",
    layout: "/operationOfficer",
  },
  {
    name: "Customer Support Supervisor",
    layout: "/customerSupportSupervisor",
  },
  {
    name: "Audits and Compliance",
    layout: "/auditCompliance",
  },
  {
    name: "Super Agent",
    layout: "/super-agent",
  },
  {
    name: "Agent",
    layout: "/sub-agent",
  },
  {
    name: 'Master Agent',
    layout: '/master-agent'
  },
  {
    name: "Sales Supervisor",
  },
  {
    name: "MMO",
  },
  {
    name: "Sales Representative",
  },
  {
    name: "Customer Support Representative",
  },
  {
    name: "Operation supervisor",
  }
];

export const getCurrentDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = ('0' + (now.getMonth() + 1)).slice(-2); // add leading zero
  const date = ('0' + now.getDate()).slice(-2); // add leading zero
  const hours = ('0' + now.getHours()).slice(-2); // add leading zero
  const minutes = ('0' + now.getMinutes()).slice(-2); // add leading zero
  const seconds = ('0' + now.getSeconds()).slice(-2); // add leading zero
  return `${date}${month}${year}-${hours}${minutes}${seconds}`;
}

export default  {
  setUserDetails,
  getMobCodes,
  checkMacAddress,
  subscriberType,
  getAmt,
  getPaymentType,
  checkDigitOnly,
  checkBankAccNo,
  getSortCls,
  reportloaderContainer,
  reportloader,
  paymentType,
  daysInMonth,
  daysInArray,
  MonthArraySM,
  MonthArrayCAP,
  YearArray,
  checkName,
  DUMMY_USER,
  checkMMOBankAccNo,
  getPrivileges,
  bankPaymentType,
  Transaction_Log_Action_Type,
  User_Log_Action_Type
};
