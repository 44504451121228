
import React, { useEffect, useState } from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { useToasts } from 'react-toast-notifications';
import { getUserId, postMethod } from "services/httpServices";
import { merchantMTDChartData, merchantYTDChartData } from "variables/charts";
import { daysInMonth, daysInArray, MonthArraySM } from "services/util";

const MerchantHeader = (props) => {
  const [totalAmt, setAmount] = React.useState('');
  const [totalPayable, setTotalPayable] = React.useState('');
  const [currentMonthAmt, setCurrentMonthAmt] = React.useState('');
  const [todayAmt, setTodayAmt] = React.useState('');
  const [totalCashTopUp, setTotalCashTopUp] = React.useState('');
  const [balanceLoading, setBalLoading] = React.useState(false);
  const { addToast } = useToasts();

  useEffect(()=> {
    fetchBalance();
  }, []);

  useEffect(()=> {
    props.showMerchantMTDLoader(true);
    setMerchantMTDChart();
  }, [props.merchantMTDMonth]);

  useEffect(()=> {
    props.showMerchantMTDLoader(true);
    setMerchantMTDChart();
  }, [props.merchantMTDYear]);

  useEffect(()=> {
    setMerchantYTDChart();
  }, [props.merchantYTDYear]);

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const fetchBalance = () => {
    let errorMsg = '';
    let id = getUserId();
    setBalLoading(true);
    postMethod('/api/dashboard/merchant/', {}, 'post')
    .then(res => {
      setBalLoading(false);
      if (
        res && res.data
      ) {
        if (res.data.responseCode === "200") {
          // Success
          setData(res.data.result);
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching list';
          notifyFailed(errorMsg);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching';
          notifyFailed(errorMsg);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
      }
    }).catch(err => {
      setBalLoading(false);
      notifyFailed('Internal Server Error');
    });
  }

  const setData = (resp) => {
    let paid = resp.totalReceived ? Number(resp.totalReceived) : 0;
    let pending = resp.totalPending ? Number(resp.totalPending) : 0;
    let monthAmt = resp.monthlyReceived ? Number(resp.monthlyReceived) : 0;
    let todayAmt = resp.todayReceived ? Number(resp.todayReceived) : 0;
    let cashTopUp = resp.totalTopUp ? Number(resp.totalTopUp) : 0;
    setAmount(paid);
    setTotalPayable(pending);
    setCurrentMonthAmt(monthAmt);
    setTodayAmt(todayAmt);
    setTotalCashTopUp(cashTopUp);
  }

  const getAmt = (amt) => {
    return amt?.toLocaleString();
  }

  const setMerchantMTDChart = () => {
    var errorMsg = '';
    var data = {
      'Year': Number(props.merchantMTDYear),
      'Month': Number(props.merchantMTDMonth) + 1,
      'Status': 'Paid'
    };
    postMethod('/api/dashboard/merchant/monthlychart/', data, 'post')
    .then(res => {
      if (
        res && res.data
      ) {
        if (
          res.data.responseCode === "200" && 
          res.data.result && res.data.result.list
        ) {
          // Success
          let month = Number(props.merchantMTDMonth);
          let year = Number(props.merchantMTDYear);
          let totalDays = daysInMonth(month + 1, year);
          let arr = daysInArray(totalDays);
          let valArr = [];

          for (let i = 0; i < arr.length; i++) {
            let val = res.data.result.list.find(l => l.day == arr[i]);
            if (val && val.hasOwnProperty('amount')) {
              valArr.push(Number(val.amount));
            } else {
              valArr.push(0);
            }
          }
          let mtdChart = merchantMTDChartData({daysArr: arr, dataArr: valArr});
          props.showMerchantMTD(mtdChart);
          props.showMerchantMTDLoader(false);
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching charts';
          notifyFailed(errorMsg);
          props.showMerchantMTDLoader(false);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching charts';
          notifyFailed(errorMsg);
          props.showMerchantMTDLoader(false);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        props.showMerchantMTDLoader(false);
      }
    }).catch(err => {
      notifyFailed('Internal Server Error');
      props.showMerchantMTDLoader(false);
    });
  }

  const setMerchantYTDChart = () => {
    var errorMsg = '';
    var data = {
      'Year': Number(props.merchantYTDYear),
      'Status': 'Paid'
    };
    postMethod('/api/dashboard/merchant/yearlychart/', data, 'post')
    .then(res => {
      if (
        res && res.data
      ) {
        if (
          res.data.responseCode === "200" && 
          res.data.result && res.data.result.list
        ) {

          let monthArr = MonthArraySM;
          let dataArr = [];
          for (let i = 1; i <= 12; i++) {
            let val = res.data.result.list.find(l => l.month == i);
            if (val && val.hasOwnProperty('amount')) {
              dataArr.push(Number(val.amount));
            } else {
              dataArr.push(0);
            }
          }
          let ytdChart = merchantYTDChartData({monthArr: monthArr, dataArr: dataArr});
          props.showMerchantYTD(ytdChart);
          props.showMerchantYTDLoader();

        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching charts';
          notifyFailed(errorMsg);
          props.showMerchantYTDLoader(false);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching charts';
          notifyFailed(errorMsg);
          props.showMerchantYTDLoader(false);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        props.showMerchantYTDLoader(false);
      }
    }).catch(err => {
      notifyFailed('Internal Server Error');
      props.showMerchantYTDLoader(false);
    });

  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row className="mt-2">
              <Col lg="6" xl="3" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Total Received</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i class="fas fa-money-bill-alt"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? 
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> : 
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(totalAmt)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Total Receivable</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-chart-pie" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                    {balanceLoading ? 
                      <div className="stage ml-4 mt-2 mb-3">
                        <div className="dot-flashing"></div>
                      </div> : 
                      <span className="h2 font-weight-bold mb-0">
                        {getAmt(totalPayable)}
                      </span>
                    }
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Monthly Received</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-purple text-white rounded-circle shadow">
                          <i class="fas fa-chart-bar"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                    {balanceLoading ? 
                      <div className="stage ml-4 mt-2 mb-3">
                        <div className="dot-flashing"></div>
                      </div> : 
                      <span className="h2 font-weight-bold mb-0">
                        {getAmt(currentMonthAmt)}
                      </span>
                    }
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Today Received</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-chart-pie" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                    {balanceLoading ? 
                      <div className="stage ml-4 mt-2 mb-3">
                        <div className="dot-flashing"></div>
                      </div> : 
                      <span className="h2 font-weight-bold mb-0">
                        {getAmt(todayAmt)}
                      </span>
                    }
                    </p>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="3" md="6">
                <Card className="card-stats mt-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Total Cashtop up</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-purple text-white rounded-circle shadow">
                          <i className="fas fa-money-bill-alt" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                    {balanceLoading ? 
                      <div className="stage ml-4 mt-2 mb-3">
                        <div className="dot-flashing"></div>
                      </div> : 
                      <span className="h2 font-weight-bold mb-0">
                        {getAmt(totalCashTopUp)}
                      </span>
                    }
                    </p>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default MerchantHeader;