import React, { useEffect } from "react";
import {
  FormGroup,
  Input,
  Button,
  Row,
  Col
} from "reactstrap";
import moment from "moment";
import { getUserId, postMethod } from "services/httpServices";
import { useToasts } from 'react-toast-notifications';
import { bankPaymentType } from "services/util";
import { getMethod } from "services/httpServices";

const PAYMENT_TYPE = bankPaymentType;

const BankReport = (props) => {

  const [startDate, setStartDate] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [isMounted, setIsMounted] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [submitButton, setSubmitButton] = React.useState(false);
  const [bankId, setBankId] = React.useState('');
  const [paymentType, setPaymentType] = React.useState('');
  const [banklist, setBankList] = React.useState([]);
  const { addToast } = useToasts();

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  useEffect(()=> {
    fetchBankList();
  }, []);


  useEffect(() => {
    if (isMounted) {
      fetchReport();
    }
  }, [props.currentPage]);

  useEffect(() => {
    if (isMounted) {
      fetchReport();
    }
  }, [props.sort]);

  useEffect(() => {
    props.parentLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (props.pdfLoading && isMounted) {
      fetchReportData();
    }
  }, [props.pdfLoading])

  useEffect(() => {
    if (props.xlLoading && isMounted) {
      fetchReportData();
    }
  }, [props.xlLoading])


  const validate = () => {
    var error = {};
    if (!startDate) {
      error.startDate = 'Please select start date';
    }
    return error;
  }

  const onSubmitReport = () => {
    setSubmitButton(true);
    if (props.currentPage != 0) {
      props.changePage(0);
    } else {
      fetchReport();
    }
  }

  const changePaymentFilter = (val) => {
    setPaymentType(val);
  }

  const changeBank = (val) => {
    setBankId(val);
  }

  const fetchBankList = () => {
    let errorMsg = '';
    getMethod('/api/bankProfile/nonapiddllist', 'get')
    .then(res => {
      if (
        res && res.data
      ) {
        if (res.data.responseCode === "200") {
          // Success
          if (
            !res.data.result ||
            !res.data.result.length
          ) {
            res.data.result = [];
          }
          setBankList(res.data.result);
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching bank list';
          notifyFailed(errorMsg);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching bank list';
          notifyFailed(errorMsg);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
      }
    }).catch(err => {
      notifyFailed('Internal Server Error');
    });
  }

  const fetchReport = () => {
    const validationErrors = validate();
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      let errorMsg = '';
      setLoading(true);
      let data = {
        'FromDate': moment(new Date(startDate)).format('DD/MM/YYYY'),
        // 'ToDate': moment(new Date(endDate)).format('DD/MM/YYYY'),
        'PageNumber': Number(props.currentPage + 1),
        'PerPage': pageSize
      }
      if (bankId) {
        data['BankId'] = Number(bankId);
      }
      if (paymentType) {
        data['PaymentType'] = paymentType;
      }

      if (props.sort && props.sort.sortPaymentFrom == "1") {
        data['SortingOn'] = 'PaymentFrom';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortPaymentFrom == "2") {
        data['SortingOn'] = 'PaymentFrom';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortSubscriberName == "1") {
        data['SortingOn'] = 'SubscriberName';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortSubscriberName == "2") {
        data['SortingOn'] = 'SubscriberName';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortPaymentType == "1") {
        data['SortingOn'] = 'PaymentType';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortPaymentType == "2") {
        data['SortingOn'] = 'PaymentType';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortDate == "1") {
        data['SortingOn'] = 'UpdatedAt';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortDate == "2") {
        data['SortingOn'] = 'UpdatedAt';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortAmount == "1") {
        data['SortingOn'] = 'Amount';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortAmount == "2") {
        data['SortingOn'] = 'Amount';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortFeesAmount == "1") {
        data['SortingOn'] = 'FeesAmount';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortFeesAmount == "2") {
        data['SortingOn'] = 'FeesAmount';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortStatus == "1") {
        data['SortingOn'] = 'Status';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortStatus == "2") {
        data['SortingOn'] = 'Status';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortPaymentMethod == "1") {
        data['SortingOn'] = 'PaymentMethod';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortPaymentMethod == "2") {
        data['SortingOn'] = 'PaymentMethod';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortMerchantName == "1") {
        data['SortingOn'] = 'MerchantName';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortMerchantName == "2") {
        data['SortingOn'] = 'MerchantName';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortReferenceId == "1") {
        data['SortingOn'] = 'ReferenceId';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortReferenceId == "2") {
        data['SortingOn'] = 'ReferenceId';
        data['IsDescending'] = true;
      }

      postMethod('/api/Reports/BankTransactionReport', JSON.stringify(data), 'post')
        .then(res => {
          setLoading(false);
          setIsMounted(true);
          setSubmitButton(false);
          if (
            res && res.data
          ) {
            if (res.data.responseCode === "200" &&
              res.data.result) {
              // Success
              if (
                !res.data.result.list ||
                !res.data.result.list.length
              ) {
                res.data.result.list = [];
              }
              props.parentCallback(res.data.result);
            } else if (res.data.responseCode === "400") {
              // Error
              errorMsg = res.data.responseMessage || 'Error in fetching list';
              notifyFailed(errorMsg);
            } else {
              // Unknown Error
              errorMsg = 'Unknown Error in fetching';
              notifyFailed(errorMsg);

            }
          } else {
            errorMsg = 'Unknown Error';
            notifyFailed(errorMsg);

          }
        }).catch(err => {
          setLoading(false);
          setIsMounted(true);
          notifyFailed('Internal Server Error');
        })
    }
  }

  const fetchReportData = () => {
    const validationErrors = validate();
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      let errorMsg = '';
      let data = {
        'FromDate': moment(new Date(startDate)).format('DD/MM/YYYY'),
        // 'ToDate': moment(new Date(endDate)).format('DD/MM/YYYY'),
        "Status": "",
        'PageNumber': 1,
        'PerPage': props.totalRec
      }

      if (bankId) {
        data['BankId'] = Number(bankId);
      }
      if (paymentType) {
        data['PaymentType'] = paymentType;
      }

      if (props.sort && props.sort.sortPaymentFrom == "1") {
        data['SortingOn'] = 'PaymentFrom';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortPaymentFrom == "2") {
        data['SortingOn'] = 'PaymentFrom';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortSubscriberName == "1") {
        data['SortingOn'] = 'SubscriberName';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortSubscriberName == "2") {
        data['SortingOn'] = 'SubscriberName';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortPaymentType == "1") {
        data['SortingOn'] = 'PaymentType';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortPaymentType == "2") {
        data['SortingOn'] = 'PaymentType';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortDate == "1") {
        data['SortingOn'] = 'UpdatedAt';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortDate == "2") {
        data['SortingOn'] = 'UpdatedAt';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortAmount == "1") {
        data['SortingOn'] = 'Amount';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortAmount == "2") {
        data['SortingOn'] = 'Amount';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortFeesAmount == "1") {
        data['SortingOn'] = 'FeesAmount';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortFeesAmount == "2") {
        data['SortingOn'] = 'FeesAmount';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortStatus == "1") {
        data['SortingOn'] = 'Status';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortStatus == "2") {
        data['SortingOn'] = 'Status';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortPaymentMethod == "1") {
        data['SortingOn'] = 'PaymentMethod';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortPaymentMethod == "2") {
        data['SortingOn'] = 'PaymentMethod';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortMerchantName == "1") {
        data['SortingOn'] = 'MerchantName';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortMerchantName == "2") {
        data['SortingOn'] = 'MerchantName';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortReferenceId == "1") {
        data['SortingOn'] = 'ReferenceId';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortReferenceId == "2") {
        data['SortingOn'] = 'ReferenceId';
        data['IsDescending'] = true;
      }

      postMethod('/api/Reports/BankTransactionReport', JSON.stringify(data), 'post')
        .then(res => {
          setIsMounted(true);
          if (
            res && res.data
          ) {
            if (res.data.responseCode === "200" &&
              res.data.result) {
              // Success
              if (
                !res.data.result.list ||
                !res.data.result.list.length
              ) {
                res.data.result.list = [];
              }
              props.parentReportData(res.data.result);
            } else if (res.data.responseCode === "400") {
              // Error
              errorMsg = res.data.responseMessage || 'Error in fetching list';
              notifyFailed(errorMsg);
              props.errorReportData();
            } else {
              // Unknown Error
              errorMsg = 'Unknown Error in fetching';
              notifyFailed(errorMsg);
              props.errorReportData();
            }
          } else {
            errorMsg = 'Unknown Error';
            notifyFailed(errorMsg);
            props.errorReportData();
          }
        }).catch(err => {
          setIsMounted(true);
          notifyFailed('Internal Server Error');
          props.errorReportData();
        })
    }
  }

  return (
    <>
      &nbsp; &nbsp; &nbsp; &nbsp;

      <Row>
        <Col className={errors.startDate ? 'mt-3': ''}>

          <Row>
            <Col lg="12">

              <small><strong>Select Date</strong></small>
            </Col>
          </Row>

          <Row>
            <Col lg="12">


              <FormGroup className="mb-0">

                <div class="dropdown-border">
                  <Input
                    type="date"
                    id="status"
                    className="form-control-alternative status-filter"
                    name="startDate"
                    placeholder="Start Date"
                    max={moment().format("YYYY-MM-DD")}
                    onChange={(e) => setStartDate(e.target.value)}
                  >
                  </Input>
                </div>
              </FormGroup>


            </Col>
          </Row>

          <Row>
            <Col lg="12">

              {errors.startDate && <div className="text-left text-danger">
                <small>* {errors.startDate}</small>
              </div>}

            </Col>
          </Row>
        </Col>

        <Col className={errors.startDate ? 'mt-3': ''}>
          <Row>
            <Col lg="12">
              <small><strong>Bank</strong></small>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <div class="dropdown-border">
                  <Input
                    type="select"
                    id="merchant"
                    className="form-control-alternative status-filter"
                    name="bankId"
                    value={bankId}
                    onChange={(e) => changeBank(e.target.value)}
                  >
                    <option value={0}>----Select Bank----</option>
                    {banklist.map(c =>
                      <option key={c.key} value={c.key}>{c.value}</option>
                    )}
                  </Input>
                </div>
              </FormGroup>

            </Col>
          </Row>

          <Row>
            <Col lg="12">
              {errors.bankId && <div className="text-left text-danger">
                <small>* {errors.bankId}</small>
              </div>}
            </Col>
          </Row>
        </Col>

        <Col className={errors.startDate ? 'mt-3': ''}>
          <Row>
            <Col lg="12">
              <small><strong>Payment type</strong></small>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <div class="dropdown-border">
                  <Input
                    type="select"
                    id="paymentType"
                    className="form-control-alternative status-filter"
                    name="paymentType"
                    value={paymentType}
                    onChange={(e) => changePaymentFilter(e.target.value)}
                  >
                    <option value=''>----Select Payment Type ----</option>
                    {PAYMENT_TYPE.map(c =>
                      <option key={c.key} value={c.key}>{c.value}</option>
                    )}
                  </Input>
                </div>
              </FormGroup>

            </Col>
          </Row>

        </Col>

      </Row>

      <Row>

        <Col>
          <Button color="primary"
            className="mt-3"
            onClick={onSubmitReport}
            disabled={loading}>
            Submit &nbsp; &nbsp;
            {loading && submitButton && <i class="fa fa-spinner fa-spin"></i>}
          </Button>
        </Col>

      </Row>

      {/* &nbsp;&nbsp; */}

    </>
  )
};

export default BankReport;