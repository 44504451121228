import React, { useEffect } from "react";
import { Button, Row, Col } from "reactstrap";
import { postMethod } from "services/httpServices";
import { useToasts } from "react-toast-notifications";

const SettlementLiquidityAnalysisReport = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [isMounted, setIsMounted] = React.useState(false);
  const [submitButton, setSubmitButton] = React.useState(false);
  const [pageSize] = React.useState(10);
  const { addToast } = useToasts();

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  useEffect(() => {
    if (isMounted) {
      fetchReport();
    }
  }, [props.currentPage]);

  useEffect(() => {
    props.parentLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (props.pdfLoading && isMounted) {
      fetchReportData();
    }
  }, [props.pdfLoading]);

  useEffect(() => {
    if (props.xlLoading && isMounted) {
      fetchReportData();
    }
  }, [props.xlLoading]);

  const onSubmitReport = () => {
    setSubmitButton(true);
    if (props.currentPage != 0) {
      props.changePage(0);
    } else {
      fetchReport();
    }
  };

  const fetchReport = () => {
    let errorMsg = "";
    setLoading(true);
    let data = {
      Status: "",
      PageNumber: Number(props.currentPage + 1),
      PerPage: pageSize,
    };

    postMethod(
      "/api/reports/SettlementLiquidityAnalysisReport",
      JSON.stringify(data),
      "post"
    )
      .then((res) => {
        setLoading(false);
        setIsMounted(true);
        setSubmitButton(false);
        if (res && res.data) {
          if (res.data.responseCode === "200" && res.data.result) {
            // Success
            if (!res.data.result.list || !res.data.result.list.length) {
              res.data.result.list = [];
            }
            props.parentCallback(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        setIsMounted(true);
        notifyFailed("Internal Server Error");
      });
  };

  const fetchReportData = () => {
    let errorMsg = "";
    let data = {
      Status: "",
      PageNumber: 1,
      PerPage: props.totalRec,
    };

    postMethod(
      "/api/reports/SettlementLiquidityAnalysisReport",
      JSON.stringify(data),
      "post"
    )
      .then((res) => {
        setIsMounted(true);
        if (res && res.data) {
          if (res.data.responseCode === "200" && res.data.result) {
            // Success
            if (!res.data.result.list || !res.data.result.list.length) {
              res.data.result.list = [];
            }
            props.parentReportData(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
            props.errorReportData();
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
            props.errorReportData();
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          props.errorReportData();
        }
      })
      .catch((err) => {
        setIsMounted(true);
        notifyFailed("Internal Server Error");
        props.errorReportData();
      });
  };

  return (
    <>
      &nbsp; &nbsp; &nbsp; &nbsp;
      <Row>
        <Col lg="12">
          <Button
            color="primary"
            className="mt-3"
            onClick={onSubmitReport}
            disabled={loading}
          >
            Submit &nbsp; &nbsp;
            {loading && submitButton && <i class="fa fa-spinner fa-spin"></i>}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SettlementLiquidityAnalysisReport;
