import React from "react";

const loaderContainer = {
  position: 'fixed',
  top: '300px',
  left: 0,
  width: '100%',
  heigth: '100%',
  backgroundColor: '#F8F8F8AD',
  // display: 'none'
};

const loader = {
  left: '55%',
  top: '50%',
  zIndex: '1000',
  position: 'absolute'
};

const FullPageLoader = (props) => {

  const outerDiv = props && props.loaderContainer ? props.loaderContainer : loaderContainer;
  const innerDiv = props && props.loader ? props.loader : loader;
  return (
    <div style={outerDiv}>
      <div style={innerDiv}>
        {/* <img src={LoaderGIF}>
        </img> */}
        {/* <i className="fa fa-spinner fa-spin" /> {props.label ? props.label : 'Loading.....'} */}
        <div className="spinner-border" role="status">
          <span className="sr-only"></span>
        </div>
        &nbsp;
        <span>Loading...</span>
      </div>
    </div>
  )
};

export default FullPageLoader;