/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */

import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Row,
  Button,
  Col,
  CardFooter,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
} from "reactstrap";
// core components
import { useToasts } from "react-toast-notifications";
import { postMethod } from "../../services/httpServices";
import ReactPaginate from "react-paginate";
import { btnCss } from "appConfig";
import { iconCss } from "appConfig";

const SubscriberLoanPaymentHistory = (props) => {
  const [loading, setLoading] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");

  const { addToast } = useToasts();

  useEffect(() => {
    if (props?.id) {
      fetchData(props?.id);
    }
  }, []);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const fetchData = async (id) => {
    let errorMsg = "";
    setLoading(true);

    postMethod("/api/Subscriber/Loan/LoanPaymentHistory/" + id, {}, "post")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200" && res.data.result) {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setFilteredList(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  let filteredData = filteredList;

  if (searchText) {
    filteredData = filteredList.filter((item) =>
      Object.values(item).some((value) =>
        String(value).includes(searchText.trim())
      )
    );
  }
  return (
    <>
      {/* Page content */}
      <div>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Loan Payment History</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={props.closeModal}
                      size="xs"
                      close
                      // className="fa fa-times"
                      style={{ cursor: "pointer", fontSize: "25px" }}
                    ></Button>
                  </Col>
                </Row>
              </CardHeader>
              <Row>
                <Col
                  style={{
                    display: "flex",
                    gap: "12px",
                    padding: "12px",
                    width: "100%",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <FormGroup className="mb-0 navbar-search ">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="search-cls"
                        placeholder="Search"
                        type="text"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <Button
                    color="primary"
                    onClick={() => fetchData(props.id)}
                    size="sm"
                    style={btnCss}
                    data-toggle="tooltip"
                    title="Refresh"
                    className="mr-4"
                  >
                    <i class="fas fa-redo fa-lg" style={iconCss}></i>
                  </Button>
                </Col>
              </Row>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Loan ID</th>
                    <th scope="col">subscriber Name</th>
                    <th scope="col">amount</th>
                    <th scope="col">Status</th>
                    <th scope="col">description</th>
                    <th scope="col">created By</th>
                    <th scope="col">created Date</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    filteredData
                      .slice(currentPage * 10, (currentPage + 1) * 10)
                      .map((mmo, i) => {
                        return (
                          <tr>
                            <th>{mmo.loanId}</th>
                            <td>{mmo.subscriberName}</td>
                            <td>{mmo.amount}</td>
                            <td className="text-center" title={mmo.status}>
                              <i
                                class={`fas ${
                                  mmo.status === "Paid"
                                    ? "fa-check-circle"
                                    : "fa-exclamation"
                                } ${
                                  mmo.status === "Paid"
                                    ? "text-green"
                                    : "text-red"
                                }`}
                              ></i>
                            </td>
                            <td style={{ textWrap: "wrap" }}>
                              {mmo.description}
                            </td>
                            <td>{mmo.createdBy}</td>
                            <td>
                              {new Date(mmo.createdAt).toLocaleDateString()}
                            </td>

                            {/* <td>
                            {privileges.edit && (
                              <Button
                                color="primary"
                                onClick={() => {
                                  setEditObj(mmo);
                                  setOpenEditModal(true);
                                }}
                                size="sm"
                                style={btnCss}
                                data-toggle="tooltip"
                                title="Edit"
                              >
                                <i
                                  class="fas fa-edit fa-lg"
                                  style={iconCss}
                                ></i>
                              </Button>
                            )}
                            {!mmo.isBlock ? (
                              <Button
                                color="primary"
                                onClick={() => openDelete(mmo)}
                                size="sm"
                                style={btnCss}
                                data-toggle="tooltip"
                                title="Block"
                              >
                                <i
                                  class="fa fa-eye-slash fa-lg"
                                  style={iconCss}
                                ></i>
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                onClick={() => openDelete(mmo)}
                                size="sm"
                                style={btnCss}
                                data-toggle="tooltip"
                                title="Unblock"
                              >
                                <i
                                  class="fa fa-eye-slash fa-lg"
                                  style={{ color: "#FF0000" }}
                                ></i>
                              </Button>
                            )}
                          </td> */}
                          </tr>
                        );
                      })
                  ) : (
                    <tr>
                      <th
                        scope="row"
                        colSpan={"6"}
                        className="text-center w-100"
                      >
                        {" "}
                        {loading ? "Loading..." : "No data available!"}
                      </th>
                    </tr>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {filteredData.length > 0 && (
                  <Row>
                    <Col lg="6">
                      <div>
                        <small>
                          <strong>Total Records : {filteredData.length}</strong>
                        </small>
                      </div>
                    </Col>

                    <Col lg="6">
                      <nav aria-label="...">
                        <div className="float-right">
                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={Math.ceil(filteredData.length / 10)}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={"paginationV2"}
                            subContainerClassName={"pages paginationV2"}
                            activeClassName={"active"}
                            forcePage={currentPage}
                          />
                        </div>
                      </nav>
                    </Col>
                  </Row>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </div>
    </>
  );
};

export default SubscriberLoanPaymentHistory;
