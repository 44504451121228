
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Col,
  Badge
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
// import MMO from "./add-mmo";
// import Bank from "./add-bank";
import { useToasts } from 'react-toast-notifications';
import { getMethod, getUserId, postMethod } from "../../services/httpServices";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import NoRecordFound from "../../components/NoRecordFound/NoRecordFound";
import { fetchSettlementBank, setSettlementBank, settlementBankError } from "./action";
import { connect } from "react-redux";
import { FinanceUserStatus, btnCss, iconCss } from "appConfig";
import ReactPaginate from 'react-paginate';
import { getSortCls } from "services/util";

const STATUS = FinanceUserStatus;

const sortIS = {
  sortBankName: 0,
  sortCollectionType: 0,
  sortMerchantName: 0,
  sortAmount: 0
};

const CollectionList = (props) => {

  const [open, setOpen] = React.useState(false);
  const [banklist, setBankList] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [pagesCount, setPageCount] = React.useState(0);
  const [timeout, setTimeoutNew] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [openApproveModal, setOpenApproveModal] = React.useState(false);
  const [approveObj, setApproveObj] = React.useState(null);
  const [approveReason, setApproveReason] = React.useState(null);
  const [settleBankId, setSettleBankId] = React.useState(null);
  const [settleBankList, setSettleBankList] = React.useState([]);
  const [errors, setError] = React.useState({});
  const [modalLoading, setModalLoading] = React.useState(false);
  const [statusFilter, setStatusFilter] = React.useState(STATUS[0].key);
  const [totalList, setTotalList] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [refresh, setRefresh] = React.useState(false);
  const [coreTransId, setCoreTransId] = React.useState(null);
  const [totalRec, setTotalRec] = React.useState(0);
  const [sort, setSort] = React.useState(sortIS);
  const [isMounted, setIsMounted] = React.useState(false);
  const [openLockModal, setOpenLockModal] = React.useState(false);
  const [openUnlockModal, setOpenUnlockModal] = React.useState(false);

  const { addToast } = useToasts();

  React.useEffect(() => {
    // fetchBank();
    props.fetchSettlementBankInfo()
  }, []);

  React.useEffect(() => {
    fetchBank();
  }, [currentPage]);

  React.useEffect(() => {
    if (
      props.finance.settlementBankInfo &&
      props.finance.settlementBankInfo.list.length
    ) {
      setSettleBankList(props.finance.settlementBankInfo.list);
      setSettleBankId(props.finance.settlementBankInfo.list[0].key);
    }
  }, [props.finance.settlementBankInfo.list]);

  // React.useEffect(() => {
  //   setListDataWrapper(totalList);
  // }, [statusFilter]);

  // React.useEffect(()=> {
  //   if (refresh) {
  //     reloadListHandle();
  //   }
  // }, [refresh])

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (currentPage != 0) {
        setCurrentPage(0);
      } else {
        fetchBank();
      }
    }
  }, [statusFilter]);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (currentPage != 0) {
        setCurrentPage(0);
      } else {
        fetchBank();
      }
    }
  }, [sort]);

  React.useEffect(() => {
    if (refresh && isMounted) {
      setSearchText('');
      setSort(sortIS);
      if (currentPage == 0) {
        fetchBank();
      } else {
        setCurrentPage(0);
      }
    }
  }, [refresh]);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (
        searchText && searchText.length >= 3 ||
        searchText.length == 0
      ) {
        if (currentPage != 0) {
          setCurrentPage(0);
        } else {
          fetchBank();
        }
      }
    }
  }, [searchText]);


  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const notifySuccess = (text) => {
    if (!text) {
      text = 'Save successfully';
    }
    addToast(text, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  const handleClick = (e, index) => {

    e.preventDefault();
    setCurrentPage(index);

  }

  const getSearchHandle = (val, blist) => {
    let filteredList = [];
    let banklist = blist;
    for (let i = 0; i < banklist.length; i++) {
      if (
        (banklist[i].bankName &&
          banklist[i].bankName
            .trim()
            .toLowerCase()
            .includes(val.trim().toLowerCase())) ||
        (banklist[i].merchantName &&
          banklist[i].merchantName
            .trim()
            .toLowerCase()
            .includes(val.trim().toLowerCase()))
      ) {
        filteredList.push(banklist[i]);
      }
    }
    return filteredList;
  }

  const getSearch = (val) => {
    setSearchText(val);
  }

  const fetchBank = async () => {
    let errorMsg = '';
    setLoading(true);

    let data = {
      'PageNumber': Number(currentPage + 1),
      'PerPage': pageSize,
      'Status': statusFilter
    };

    if (searchText && searchText.length >= 3) {
      data['SearchText'] = searchText;
      // data['PageNumber'] = 0;
    }

    if (sort.sortBankName == "1") {
      data['SortingOn'] = 'BankName';
      data['IsDescending'] = false;
    } else if (sort.sortBankName == "2") {
      data['SortingOn'] = 'BankName';
      data['IsDescending'] = true;
    } else if (sort.sortCollectionType == "1") {
      data['SortingOn'] = 'CollectionType';
      data['IsDescending'] = false;
    } else if (sort.sortCollectionType == "2") {
      data['SortingOn'] = 'CollectionType';
      data['IsDescending'] = true;
    } else if (sort.sortMerchantName == "1") {
      data['SortingOn'] = 'MerchantName';
      data['IsDescending'] = false;
    } else if (sort.sortMerchantName == "2") {
      data['SortingOn'] = 'MerchantName';
      data['IsDescending'] = true;
    } else if (sort.sortAmount == "1") {
      data['SortingOn'] = 'Amount';
      data['IsDescending'] = false;
    } else if (sort.sortAmount == "2") {
      data['SortingOn'] = 'Amount';
      data['IsDescending'] = true;
    }

    postMethod('/api/Collection/FinancerCollectionList', data, 'post')
      .then(res => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        if (
          res && res.data
        ) {

          if (res.data.responseCode === "200" &&
            res.data.result) {
            // Success
            if (
              !res.data.result.list ||
              !res.data.result.list.length
            ) {
              res.data.result.list = [];
            }
            setTotalList(res.data.result.list);
            setFilteredList(res.data.result.list)
            let pc = Math.ceil(res.data.result.totalRecords / pageSize);
            setTotalRec(res.data.result.totalRecords);
            setPageCount(pc);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in fetching list';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching';
            notifyFailed(errorMsg);

          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);

        }
      }).catch(err => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        notifyFailed('Internal Server Error');
      })
  }

  const setListData = (resp) => {
    let arr = [];
    for (let i = 0; i < resp.length; i++) {
      if (resp[i].status === statusFilter) {
        arr.push(resp[i]);
      }
    }
    setBankList(arr);
    return arr;
  }

  const approve = (trans) => {
    setOpenApproveModal(true);
    setCoreTransId(null);
    setApproveObj(trans);
  }

  const closeApproveModal = () => {
    // setApproveObj(null);
    setError({});
    setApproveReason(null);
    setCoreTransId(null);
    setOpenApproveModal(false);
    if (settleBankList && settleBankList.length) {
      setSettleBankId(settleBankList[0].key);
    }
  }

  const approveManual = (trans) => {
    setOpenLockModal(true);
    setCoreTransId(null);
    setApproveObj(trans);
  }

  const rejectManual = (trans) => {
    setOpenUnlockModal(true);
    setCoreTransId(null);
    setApproveObj(trans);
  }

  const closeLockModal = () => {
    setApproveObj(null);
    setError({});
    setApproveReason(null);
    setCoreTransId(null);
    setOpenLockModal(false);
    if (settleBankList && settleBankList.length) {
      setSettleBankId(settleBankList[0].key);
    }
  }

  const closeUnlockModal = () => {
    setApproveObj(null);
    setError({});
    setApproveReason(null);
    setCoreTransId(null);
    setOpenUnlockModal(false);
    if (settleBankList && settleBankList.length) {
      setSettleBankId(settleBankList[0].key);
    }
  }

  const changeSettleBank = (e) => {
    setSettleBankId(e.target.value);
  }

  const validate = () => {
    let errors = {};
    if (!approveReason) {
      errors.approveReason = 'Approval reason is required';
    }
    if (!settleBankId) {
      errors.settleBankId = 'Please select settlement bank';
    }
    // if (!coreTransId) {
    //   errors.coreTransId = 'Please provide core banking transaction id';
    // }
    return errors;
  }

  const validateManual = (status) => {
    let errors = {};
    if (!settleBankId) {
      errors.settleBankId = 'Please select settlement bank';
    }
    if (status == 'Transferred' && !coreTransId) {
      errors.coreTransId = 'Please provide core banking transaction id';
    }
    return errors;
  }


  const approvePayment = () => {
    const validationErrors = validate();
    setError(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      let errorMsg = '';
      var data = {
        'CollectionIds': approveObj.collectionIds,
        'SettlementAmount': approveObj.amount,
        'Description': approveReason,
        'UserId': getUserId(),
        'BankSettlementId': Number(settleBankId),
        'CorePaymentTransactionId': coreTransId,
        'CollectionType': approveObj.collectionType,
        'BankId': approveObj.bankId,
        'Status': 'Approved',
        'MerchantFees': approveObj.commissionAmount
      }
      setModalLoading(true);
      let url = '/api/Collection/SendForApproval/';
      postMethod(url, JSON.stringify(data), 'post')
        .then(res => {
          setModalLoading(false);
          if (
            res && res.data
          ) {
            if (res.data.responseCode === "200") {
              // Success
              notifySuccess('Approved Successfuly');
              closeApproveModal();
              fetchBank();
            } else if (res.data.responseCode === "400") {
              // Error
              errorMsg = res.data.responseMessage || 'Error in approving';
              closeApproveModal();
              notifyFailed(errorMsg);
              fetchBank();
            } else {
              // Unknown Error
              errorMsg = 'Unknown Error in fetching';
              notifyFailed(errorMsg);
              closeApproveModal();
              fetchBank();
            }
          } else {
            errorMsg = 'Unknown Error';
            notifyFailed(errorMsg);
            closeApproveModal();
            fetchBank();
          }
        }).catch(err => {
          setModalLoading(false);
          errorMsg = 'Internal Server Error';
          notifyFailed(errorMsg);
          closeApproveModal();
          fetchBank();
        });
    }

  }

  const closeManaualModal = (status) => {
    if (status == 'Transferred') {
      closeLockModal();
    } else if (status == 'Rejected') {
      closeUnlockModal();
    }
  }

  const approveManualPayment = (status) => {
    const validationErrors = validateManual(status);
    setError(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      let errorMsg = '';
      var data = {
        'CollectionIds': approveObj.collectionIds,
        'SettlementAmount': approveObj.amount,
        'Description': approveReason,
        'UserId': getUserId(),
        'BankSettlementId': Number(approveObj.settlementBankId),
        'CorePaymentTransactionId': coreTransId,
        'CollectionType': approveObj.collectionType,
        'BankId': approveObj.bankId,
        'Status': status,
        'MerchantFees': approveObj.commissionAmount
      }
      setModalLoading(true);
      let url = '/api/Collection/SendForApproval/';
      postMethod(url, JSON.stringify(data), 'post')
        .then(res => {
          setModalLoading(false);
          if (
            res && res.data
          ) {
            if (res.data.responseCode === "200") {
              // Success
              let str = status == 'Rejected' ? 'Reject successfuly' : 'Approved Successfuly';
              notifySuccess(str);
              closeManaualModal(status);
              fetchBank();
            } else if (res.data.responseCode === "400") {
              // Error
              errorMsg = res.data.responseMessage || 'Error in approving';
              closeManaualModal(status);
              notifyFailed(errorMsg);
              fetchBank();
            } else {
              // Unknown Error
              errorMsg = 'Unknown Error in fetching';
              notifyFailed(errorMsg);
              closeManaualModal(status);
              fetchBank();
            }
          } else {
            errorMsg = 'Unknown Error';
            notifyFailed(errorMsg);
            closeManaualModal(status);
            fetchBank();
          }
        }).catch(err => {
          setModalLoading(false);
          errorMsg = 'Internal Server Error';
          notifyFailed(errorMsg);
          closeManaualModal(status);
          fetchBank();
        });
    }

  }

  const approveManualPaymentCb = (status) => {
    if (status == 'Rejected') {
      notifySuccess('Approved Successfuly');
    }  else {
      notifySuccess('Approved Successfuly');
    }
  }

  const getAmt = (amt) => {
    return Number(amt).toLocaleString();
  }

  const changeStatus = (val) => {
    var value = val;
    // setCurrentPage(0);
    setStatusFilter(value);
  }

  const setListDataWrapper = (resp) => {
    let arr = setListData(resp);
    if (arr && arr.length) {
      setLoading(false);
    }
    if (searchText) {
      arr = getSearchHandle(searchText, arr);
    }
    setFilteredList(arr);
    let pc = Math.ceil(arr.length / pageSize);
    setPageCount(pc);
  }

  const statusFormat = (val) => {
    let ob = STATUS.find((s) => s.key === val);
    // return ob && ob.value ? ob.value : val;
    return ob && ob.value ? (
      <Badge color="" className="badge-dot mr-4">
        <i className={ob.className} />
        {ob.value}
      </Badge>
    ) : val
  };

  const reloadList = () => {
    setSearchText('');
    setRefresh(true);
  }

  const reloadListHandle = () => {
    fetchBank();
    props.fetchSettlementBankInfo();
  }

  const getPaymentType = (type) => {
    if (type === "TopUp" || type === "topup") {
      return 'Top-up';
    } else {
      return 'Merchant Payment'
    }
  }

  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = '1';
    } else if (fieldValue == "1") {
      newValue = '2';
    } else if (fieldValue == "2") {
      newValue = '1';
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  }

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  }


  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                {/* <h3 className="mb-0">Card tables</h3> */}
                <Form className="form-inline mr-3 d-md-flex ml-lg-auto my-4"
                  onSubmit={e => { e.preventDefault(); }}>

                  <FormGroup>
                    <label
                      for="status"
                      className="form-control-label">
                      Status
                    </label>
                    &nbsp; &nbsp;
                    <div class="dropdown-border">
                      <Input
                        type="select"
                        id="status"
                        className="form-control-alternative status-filter"
                        name="statusFilter"
                        value={statusFilter}
                        onChange={(e) => changeStatus(e.target.value)}
                      >
                        {STATUS.map(c =>
                          <option key={c.key} value={c.key}>{c.value}</option>
                        )}
                      </Input>
                    </div>

                  </FormGroup>

                  &nbsp; &nbsp;
                  <FormGroup className="mb-0 navbar-search">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input className="search-cls" placeholder="Search"
                        type="text"
                        value={searchText}
                        onChange={(e) => getSearch(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>

                  &nbsp;&nbsp;
                  <Button
                    color="primary"
                    onClick={reloadList}
                    size="sm"
                    style={btnCss}
                    data-toggle="tooltip"
                    title="Refresh">
                    <i class="fas fa-redo fa-lg" style={iconCss}></i>
                  </Button>
                </Form>

              </CardHeader>

              <Modal isOpen={openLockModal} className="modal-sm">
                <ModalHeader className="bg-white border-0">
                  <h3 className="mb-0">
                    Approve Payment
                  </h3>
                </ModalHeader>
                <ModalBody>
                  <div>
                    {approveObj ?
                      <div>
                        <label>
                          <strong>
                            Do you want to manual transfers the funds ? 
                          </strong>
                        </label>
                        <Input
                          className="form-control-alternative mt-3"
                          id="approve-reason"
                          placeholder="Enter core banking transaction id"
                          type="text"
                          name="coreTransId"
                          onChange={(e) => setCoreTransId(e.target.value)}
                        />
                        {errors.coreTransId && <div className="text-left text-danger">
                          <small>* {errors.coreTransId}</small>
                        </div>}

                        <div className="text-right mt-4">
                          <Button color="primary" onClick={()=> approveManualPayment('Transferred')}
                            disabled={modalLoading}>
                            Yes {modalLoading && <i class="fa fa-spinner fa-spin"></i>}
                          </Button>{' '}
                          <Button color="secondary" onClick={closeLockModal} 
                            disabled={modalLoading}>No</Button>
                        </div>
                      </div> : <> </>
                    }
                  </div>
                </ModalBody>
              </Modal>


              <Modal isOpen={openUnlockModal} className="modal-sm">
                <ModalHeader className="bg-white border-0">
                  <h3 className="mb-0">
                    Reject Payment
                  </h3>
                </ModalHeader>
                <ModalBody>
                  <div>
                    {approveObj ?
                      <div>
                        <label>
                          <strong>
                            Unable to transfers the funds manually ?
                          </strong>
                        </label>
                        <small>
                          <i>** This action will change the status from In process to Collected</i>
                        </small>

                        <div className="text-right mt-4">
                          <Button color="primary" onClick={()=> approveManualPayment('Rejected')} disabled={modalLoading}>
                            Yes {modalLoading && <i class="fa fa-spinner fa-spin"></i>}
                          </Button>{' '}
                          <Button color="secondary" onClick={closeUnlockModal}
                            disabled={modalLoading}>No</Button>
                        </div>
                      </div> : <> </>
                    }
                  </div>
                </ModalBody>
              </Modal>


              <Modal isOpen={openApproveModal} className="modal-sm">
                <ModalHeader className="bg-white border-0">
                  <h3 className="mb-0">
                    Approve Payment
                  </h3>
                </ModalHeader>
                <ModalBody>
                  <div>
                    {approveObj ?
                      <div>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                for="settleBank"
                                className="form-control-label">
                                Settlement Bank
                              </label>
                              <Input
                                type="select"
                                id="settleBank"
                                className="form-control-alternative"
                                name="settleBankId"
                                value={settleBankId}
                                onChange={changeSettleBank}
                              >
                                {settleBankList.map(c =>
                                  <option key={c.value} value={c.key}>{c.value}</option>
                                )}
                              </Input>
                              {errors.settleBankId && <div className="text-left text-danger">
                                <small>* {errors.settleBankId}</small>
                              </div>}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Input
                          className="form-control-alternative"
                          id="approve-reason"
                          placeholder="Reason for approval"
                          type="text"
                          name="approveReason"
                          onChange={(e) => setApproveReason(e.target.value)}
                        />
                        {errors.approveReason && <div className="text-left text-danger">
                          <small>* {errors.approveReason}</small>
                        </div>}
                        &nbsp;&nbsp;
                      
                        <div className="text-right mt-4">
                          <Button color="primary" onClick={approvePayment} disabled={modalLoading}>
                            Yes {modalLoading && <i class="fa fa-spinner fa-spin"></i>}
                          </Button>{' '}
                          <Button color="secondary" onClick={closeApproveModal}
                            disabled={modalLoading}>No</Button>
                        </div>
                      </div> : <> </>
                    }
                  </div>
                </ModalBody>
              </Modal>


              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col"
                      onClick={() => sortHandle(sort.sortBankName, 'sortBankName')}>
                      Payment From
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortBankName)}
                          onClick={() => sortHandle(sort.sortBankName, 'sortBankName')}
                        />
                      </button>
                    </th>
                    <th scope="col"
                      onClick={() => sortHandle(sort.sortCollectionType, 'sortCollectionType')}>
                      Payment Type
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortCollectionType)}
                          onClick={() => sortHandle(sort.sortCollectionType, 'sortCollectionType')}
                        />
                      </button>
                    </th>
                    <th scope="col"
                      onClick={() => sortHandle(sort.sortMerchantName, 'sortMerchantName')}>
                      Merchant Name
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortMerchantName)}
                          onClick={() => sortHandle(sort.sortMerchantName, 'sortMerchantName')}
                        />
                      </button>
                    </th>
                    <th scope="col"
                      onClick={() => sortHandle(sort.sortAmount, 'sortAmount')}>
                      Amount
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortAmount)}
                          onClick={() => sortHandle(sort.sortAmount, 'sortAmount')}
                        />
                      </button>
                    </th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredList.length > 0 && filteredList.map((mmo, i) => {
                    return (
                      <tr>
                        <th scope="row">
                          {mmo.bankName}
                        </th>
                        <td>{getPaymentType(mmo.collectionType)}</td>
                        <td>{mmo.merchantName ? mmo.merchantName : '--'}</td>
                        <td>{getAmt(mmo.amount)}</td>
                        <td>{statusFormat(mmo.status)}</td>
                        <td>
                          {
                            mmo.status === "Collected" &&
                            <Button
                              color="primary"
                              onClick={() => approve(mmo)}
                              size="sm"
                              style={btnCss}
                              data-toggle="tooltip"
                              title="Send Bank Instruction">
                              <i class="fas fa-university fa-lg"
                                style={{ color: "#008000" }}></i>
                            </Button>
                          }

                          {
                            mmo.status === "InProcess" &&
                            <Button
                              color="primary"
                              onClick={() => approveManual(mmo)}
                              size="sm"
                              style={btnCss}
                              data-toggle="tooltip"
                              title="Manual fund transfer">
                               <i class="fas fa-check fa-lg"
                                style={{ color: "#008000" }}></i>
                            </Button>
                          }

                          {
                            mmo.status === "InProcess" &&
                            <Button
                              color="primary"
                              onClick={() => rejectManual(mmo)}
                              size="sm"
                              style={btnCss}
                              data-toggle="tooltip"
                              title="Send Bank Instruction">
                              <i class="fas fa-times fa-lg"
                                style={{ color: "#FF0000" }}></i>
                            </Button>
                          }
                        </td>
                      </tr>

                    )
                  }
                  )}
                </tbody>
                {loading && <FullPageLoader label={"Fetching..."} />}
                {!loading && !filteredList.length && <NoRecordFound />}
              </Table>
              <CardFooter className="py-4">
                {filteredList.length > 0 &&
                  <Row>
                    <Col lg="6">
                      <div>
                        <small>
                          <strong>
                            Total Records : {totalRec}
                          </strong>
                        </small>
                      </div>
                    </Col>

                    <Col lg="6" >
                      <nav aria-label="...">
                        <div className="float-right">
                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pagesCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={"paginationV2"}
                            subContainerClassName={"pages paginationV2"}
                            activeClassName={"active"}
                            forcePage={currentPage} />
                        </div>
                      </nav>

                    </Col>
                  </Row>}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state }
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchSettlementBankInfo: () => dispatch(fetchSettlementBank()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionList);
