import React, { useEffect } from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import { getUserId, postMethod } from "services/httpServices";
import {
  PendingChartData,
  CollectedChartData,
  PendingChartData2,
  CollectedChartData2,
  doughnutCollectionChart,
  doughnutTransferredChartChart,
  subsMTDWalletTrans,
} from "variables/charts";
import { daysInMonth, daysInArray, MonthArraySM } from "services/util";

const AdminHeader = (props) => {
  const [totalMasterAgentBalance, setTotalMasterAgentBalance] =
    React.useState(0);
  const [totalSuperAgentBalance, setTotalSuperAgentBalance] = React.useState(0);
  const [totalSubAgentBalance, setTotalSubAgentBalance] = React.useState(0);
  const [customerTopupCumulative, setCustomerTopupCumulative] =
    React.useState(0);
  const [totalWalletAmt, setWalletAmt] = React.useState(0);
  const [totalPayable, setTotalPayable] = React.useState(0);
  const [totalCommission, setTotalCommission] = React.useState(0);
  const [balanceLoading, setBalLoading] = React.useState(false);
  const [subagentList, setSubagentList] = React.useState([])
  const [superagentList, setSuperagentList] = React.useState([])
  const [masteragentList, setMasteragentList] = React.useState([])
  const [showSuperAgentsList, setShowSuperAgentsList] = React.useState(false)
  const [showSubAgentsList, setShowSubAgentsList] = React.useState(false)
  const [showMasterAgentsList, setShowMasterAgentsList] = React.useState(false)
  const [apiResponse, setApiResponse] = React.useState({});

  const { addToast } = useToasts();

  useEffect(() => {
    fetchBalance();
  }, []);

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const fetchBalance = () => {
    let errorMsg = "";
    let id = getUserId();
    setBalLoading(true);
    postMethod("/api/Admin/AdminDashboard", {}, "post")
      .then((res) => {
        setBalLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            setApiResponse(res?.data?.result);

            if (res.data.result.hasOwnProperty("totalWalletBalance")) {
              setWalletAmt(res.data.result.totalWalletBalance);
            }
            if (res.data.result.hasOwnProperty("totalPayable")) {
              setTotalPayable(res.data.result.totalPayable);
            }
            if (res.data.result.hasOwnProperty("totalMasterAgentBalance")) {
              setTotalMasterAgentBalance(
                res.data.result.totalMasterAgentBalance
              );
            }
            if (res.data.result.hasOwnProperty("totalSuperAgentBalance")) {
              setTotalSuperAgentBalance(res.data.result.totalSuperAgentBalance);
            }
            if (res.data.result.hasOwnProperty("totalSubAgentBalance")) {
              setTotalSubAgentBalance(res.data.result.totalSubAgentBalance);
            }
            if (res.data.result.hasOwnProperty("totalCommission")) {
              setTotalCommission(res.data.result.totalCommission);
            }
            if (res.data.result.hasOwnProperty("customerTopupCumulative")) {
              setCustomerTopupCumulative(
                res.data.result.customerTopupCumulative
              );
            }
            if (res.data.result.hasOwnProperty("subagentList")) {
              setSubagentList(res.data.result.subagentList);
            } else {
              setSubagentList([]);
            }

            if (res.data.result.hasOwnProperty("superagentList")) {
              setSuperagentList(res.data.result.superagentList);
            } else {
              setSuperagentList([]);
            }
            if (res.data.result.hasOwnProperty("masteragentList")) {
              setMasteragentList(res.data.result.masteragentList);
            } else {
              setMasteragentList([]);
            }
            setCharts(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setBalLoading(false);
        notifyFailed("Internal Server Error");
      });
  };

  const setCharts = (result) => {
    setChartData(result);
    setCollectedChartData(result);
  };

  const setChartData = (result) => {
    // let pendingChart = PendingChartData();
    let ob = {
      bankCommission: 0,
      mmoCommission: 0,
      merchantCommission: 0,
      subAgentCommissionCumulative: 0,
    };
    if (
      result.hasOwnProperty("bankCommission") &&
      result.bankCommission.length
    ) {
      let obj = result.bankCommission.find((ob) => ob.status == "Collected");
      if (obj && obj.commissionAmount) {
        ob.bankCommission = obj.commissionAmount;
      }
    }

    if (result.hasOwnProperty("mmoCommission") && result.mmoCommission.length) {
      let obj = result.mmoCommission.find((ob) => ob.status == "Collected");
      if (obj && obj.commissionAmount) {
        ob.mmoCommission = obj.commissionAmount;
      }
    }

    if (
      result.hasOwnProperty("merchantCommission") &&
      result.merchantCommission.length
    ) {
      let obj = result.merchantCommission.find(
        (ob) => ob.status == "Collected"
      );
      if (obj && obj.commissionAmount) {
        ob.merchantCommission = obj.commissionAmount;
      }
    }

    if (
      result.hasOwnProperty("subAgentCommissionCumulative") &&
      result.subAgentCommissionCumulative.length
    ) {
      let obj = result.subAgentCommissionCumulative.find(
        (ob) => ob.status == "Collected"
      );
      if (obj && obj.commissionAmount) {
        ob.subAgentCommissionCumulative = obj.commissionAmount;
      }
    }

    // ob.merchantCommission = 6282243.26;

    // let pendingChart = PendingChartData2(ob);
    let pendingChart = doughnutCollectionChart(ob);
    props.showPendingChart(pendingChart);
  };

  const setCollectedChartData = (result) => {
    // For Bar Chart
    // let pendingChart = CollectedChartData();

    // For Pie Chart
    let ob = {
      bankCommission: 0,
      mmoCommission: 0,
      merchantCommission: 0,
    };

    if (
      result.hasOwnProperty("bankCommission") &&
      result.bankCommission.length
    ) {
      let obj = result.bankCommission.find((ob) => ob.status == "Transferred");
      if (obj && obj.commissionAmount) {
        ob.bankCommission = obj.commissionAmount;
      }
    }

    if (result.hasOwnProperty("mmoCommission") && result.mmoCommission.length) {
      let obj = result.mmoCommission.find((ob) => ob.status == "Transferred");
      if (obj && obj.commissionAmount) {
        ob.mmoCommission = obj.commissionAmount;
      }
    }

    if (
      result.hasOwnProperty("merchantCommission") &&
      result.merchantCommission.length
    ) {
      let obj = result.merchantCommission.find(
        (ob) => ob.status == "Transferred"
      );
      if (obj && obj.commissionAmount) {
        ob.merchantCommission = obj.commissionAmount;
      }
    }
    let pendingChart = doughnutTransferredChartChart(ob);
    props.showCollectedChart(pendingChart);
  };

  const setData = (resp) => {
    let paid = 0;
    let pending = 0;
    let monthAmt = 0;
    for (let i = 0; i < resp.length; i++) {
      if (resp[i].status === "Pending") {
        pending = pending + resp[i].amount;
      } else if (resp[i].status === "Paid") {
        paid = paid + resp[i].amount;
      } else if (resp[i].status === "Monthly") {
        monthAmt = monthAmt + resp[i].amount;
      }
    }
    setWalletAmt(paid);
    setTotalPayable(pending);
  };

  const getAmt = (amt) => {
    return amt?.toLocaleString();
  };

  /**========================= MTD Wallet Transfer ===================================== */

  useEffect(() => {
    props.showAdminMTDWalletTransLoader(true);
    setAdminMTDWalletTrans();
  }, [props.adminMTDWalletTransMonth]);

  useEffect(() => {
    props.showAdminMTDWalletTransLoader(true);
    setAdminMTDWalletTrans();
  }, [props.adminMTDWalletTransYear]);

  useEffect(() => {
    props.showSubAgentMTDWalletTransLoader(true);
    setSubAgentMTDWalletTrans();
  }, [props.subAgentMTDWalletTransMonth]);

  useEffect(() => {
    props.showSubAgentMTDWalletTransLoader(true);
    setSubAgentMTDWalletTrans();
  }, [props.subAgentMTDWalletTransYear]);

  const setAdminMTDWalletTrans = () => {
    var errorMsg = "";
    var data = {
      Year: Number(props.adminMTDWalletTransYear),
      Month: Number(props.adminMTDWalletTransMonth) + 1,
      Status: "Paid",
    };
    if (!data.Month || !data.Year) {
      return;
    }
    postMethod("/api/dashboard/MonthlyCustomerCashTopup", data, "post")
      .then((res) => {
        if (res && res.data) {
          if (
            res.data.responseCode === "200" &&
            res.data.result &&
            res.data.result.list
          ) {
            // Success
            let month = Number(props.adminMTDWalletTransMonth);
            let year = Number(props.adminMTDWalletTransYear);
            let totalDays = daysInMonth(month + 1, year);
            let arr = daysInArray(totalDays);
            let sendArr = [];
            let getArr = [];

            for (let i = 0; i < arr.length; i++) {
              let val = res.data.result.list.find((l) => l.day == arr[i]);
              if (val && val.hasOwnProperty("transferFrom")) {
                getArr.push(Number(val.transferFrom));
              } else {
                getArr.push(0);
              }
              if (val && val.hasOwnProperty("transferTo")) {
                sendArr.push(Number(val.transferTo));
              } else {
                sendArr.push(0);
              }
            }
            let mtdChart = subsMTDWalletTrans({
              daysArr: arr,
              getArr: getArr,
              sendArr: sendArr,
            });
            props.showAdminMTDWalletTrans(mtdChart);
            props.showAdminMTDWalletTransLoader(false);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching charts";
            notifyFailed(errorMsg);
            props.showAdminMTDWalletTransLoader(false);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching charts";
            notifyFailed(errorMsg);
            props.showAdminMTDWalletTransLoader(false);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          props.showAdminMTDWalletTransLoader(false);
        }
      })
      .catch((err) => {
        notifyFailed("Internal Server Error");
        props.showAdminMTDWalletTransLoader(false);
      });
  };

  const setSubAgentMTDWalletTrans = () => {
    var errorMsg = "";
    var data = {
      Year: Number(props.subAgentMTDWalletTransYear),
      Month: Number(props.subAgentMTDWalletTransMonth) + 1,
      Status: "Paid",
    };
    if (!data.Month || !data.Year) {
      return;
    }
    postMethod("/api/dashboard/MonthlySubAgentCommissionMTD", data, "post")
      .then((res) => {
        if (res && res.data) {
          if (
            res.data.responseCode === "200" &&
            res.data.result &&
            res.data.result.list
          ) {
            // Success
            let month = Number(props.subAgentMTDWalletTransMonth);
            let year = Number(props.subAgentMTDWalletTransYear);
            let totalDays = daysInMonth(month + 1, year);
            let arr = daysInArray(totalDays);
            let sendArr = [];
            let getArr = [];

            for (let i = 0; i < arr.length; i++) {
              let val = res.data.result.list.find((l) => l.day == arr[i]);
              if (val && val.hasOwnProperty("transferFrom")) {
                getArr.push(Number(val.transferFrom));
              } else {
                getArr.push(0);
              }
              if (val && val.hasOwnProperty("transferTo")) {
                sendArr.push(Number(val.transferTo));
              } else {
                sendArr.push(0);
              }
            }
            let mtdChart = subsMTDWalletTrans({
              daysArr: arr,
              getArr: getArr,
              sendArr: sendArr,
            });
            props.showSubAgentMTDWalletTrans(mtdChart);
            props.showSubAgentMTDWalletTransLoader(false);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching charts";
            notifyFailed(errorMsg);
            props.showSubAgentMTDWalletTransLoader(false);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching charts";
            notifyFailed(errorMsg);
            props.showSubAgentMTDWalletTransLoader(false);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          props.showSubAgentMTDWalletTransLoader(false);
        }
      })
      .catch((err) => {
        notifyFailed("Internal Server Error");
        props.showSubAgentMTDWalletTransLoader(false);
      });
  };

  const showAgentsList = (list) => {
    return list.map((item, idx) => (
      <div className="d-flex justify-content-between w-100">
        <h5>{item?.firstName} {item?.lastName}</h5>
        <h5>{getAmt(item?.balance)}</h5>
      </div>
    ))
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}

            <Row className="mb-4">
              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Total Wallet Amount</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i class="fas fa-money-bill-alt"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(totalWalletAmt)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Total Payable</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-chart-pie" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(totalPayable)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Total Commission Amount</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                          <i class="fas fa-money-bill-alt"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(totalCommission)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* <Row>
              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0" style={{cursor:"pointer"}} onClick={() => setShowMasterAgentsList(!showMasterAgentsList)}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Master Agent Balance</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i class="ni ni-circle-08 text-white"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(totalMasterAgentBalance)}
                        </span>
                      )}
                    </p>
                    {
                      showMasterAgentsList && <Row className="border-top border-gray mt-4 p-2">
                      {showAgentsList(masteragentList)}
                    </Row>
                    }
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0" style={{cursor:"pointer"}} onClick={() => setShowSuperAgentsList(!showSuperAgentsList)}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Super Agent Balance</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <i class="ni ni-circle-08 text-white"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(totalSuperAgentBalance)}
                        </span>
                      )}
                    </p>
                    {
                      showSuperAgentsList && <Row className="border-top border-gray mt-4 p-2">
                      {showAgentsList(superagentList)}
                    </Row>
                    }
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0" style={{cursor:"pointer"}} onClick={() => setShowSubAgentsList(!showSubAgentsList)}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Sub Agent Balance</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                          <i class="ni ni-circle-08 text-white"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(totalSubAgentBalance)}
                        </span>
                      )}
                    </p>
                    {
                      showSubAgentsList && <Row className="border-top border-gray mt-4 p-2">
                      {showAgentsList(subagentList)}
                    </Row>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row> */}

            <Row className="mt-4">
              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Customer Topup Cumulative</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-teal text-white rounded-circle shadow">
                          <i class="ni ni-circle-08 text-white"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(customerTopupCumulative)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Transaction Fees</strong>
                          </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-teal text-white rounded-circle shadow">
                        <i class="fas fa-money-check-alt text-white"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                          {balanceLoading ? (
                            <div className="stage ml-4 mt-2 mb-3">
                              <div className="dot-flashing"></div>
                            </div>
                          ) : (
                            <span className="h2 font-weight-bold mb-0">
                              {getAmt(apiResponse?.transactionFees ?? 0)}
                            </span>
                          )}
                        </p>
                  </CardBody>
                </Card>
              </Col>
              
              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>FSP Fees</strong>
                          </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                        <i class="fas fa-money-check-alt text-white"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                          {balanceLoading ? (
                            <div className="stage ml-4 mt-2 mb-3">
                              <div className="dot-flashing"></div>
                            </div>
                          ) : (
                            <span className="h2 font-weight-bold mb-0">
                              {getAmt(apiResponse?.fspPortionFees ?? 0)}
                            </span>
                          )}
                        </p>
                  </CardBody>
                </Card>
              </Col>
              
              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mt-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Smartpay Fees</strong>
                          </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                        <i class="fas fa-money-check-alt text-white"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                          {balanceLoading ? (
                            <div className="stage ml-4 mt-2 mb-3">
                              <div className="dot-flashing"></div>
                            </div>
                          ) : (
                            <span className="h2 font-weight-bold mb-0">
                              {getAmt(apiResponse?.smartpayPortionFees ?? 0)}
                              </span>
                          )}
                        </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
           
          </div>
        </Container>
      </div>
    </>
  );
};

export default AdminHeader;
